// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ProductListResultModel from '../../model/product-list-result-model';
import ProductListResultDTO from '../dto/product-list-result-dto';
import ProductModel from '../../model/product-model';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ProductListResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ProductListResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => ProductModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  'items'?: ProductModel[];
  /**
  */
  'links'?: ResourceLinkModel[];

  static toModel(dto: DeepPartial<ProductListResultDTO>): ProductListResultModel;
  static toModel(dto: DeepPartial<ProductListResultDTO> | undefined | null): ProductListResultModel | undefined;
  static toModel(dto: DeepPartial<ProductListResultDTO> | undefined | null): ProductListResultModel | undefined {
    return dto ? new ProductListResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ProductListResultModel> | ProductListResultModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'items': unwrapped.items,
      'links': unwrapped.links,
    } as ProductListResultDTO;
  }
}
