












import Vue from 'vue';
import ProductLookupViewModel from './bv-product-lookup.vue.model';

export default Vue.extend({
  props: {
    value: { type: Number, default: undefined },
  },
  data: () => ({
    vm: new ProductLookupViewModel(),
  }),
  mounted() {
    this.vm.initialize();
    this.$emit('input', this.value);
  },
  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
    onSearch(value: string) {
      this.vm.productsLookupDataSource.filter.searchCriteria = value;
      this.vm.productsLookupDataSource.select();
    },
  },
});
