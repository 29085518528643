// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ProcessPendingIntegrationCallsRequestModel from '../../model/process-pending-integration-calls-request-model';
import ProcessPendingIntegrationCallsRequestDTO from '../dto/process-pending-integration-calls-request-dto';

export default abstract class ProcessPendingIntegrationCallsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ProcessPendingIntegrationCallsRequestDTO>) {
    super();
    if (data) {
      this.integrationTaskId = data.integrationTaskId ?? undefined;
      this.sourceAppSystemId = data.sourceAppSystemId ?? undefined;
      this.itemsToBeProcessed = data.itemsToBeProcessed ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  'integrationTaskId'?: number;
  /**
  * @type {int64}
  */
  'sourceAppSystemId'?: number;
  /**
  * @type {int32}
  */
  'itemsToBeProcessed'?: number;

  static toModel(dto: DeepPartial<ProcessPendingIntegrationCallsRequestDTO>): ProcessPendingIntegrationCallsRequestModel;
  static toModel(dto: DeepPartial<ProcessPendingIntegrationCallsRequestDTO> | undefined | null): ProcessPendingIntegrationCallsRequestModel | undefined;
  static toModel(dto: DeepPartial<ProcessPendingIntegrationCallsRequestDTO> | undefined | null): ProcessPendingIntegrationCallsRequestModel | undefined {
    return dto ? new ProcessPendingIntegrationCallsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ProcessPendingIntegrationCallsRequestModel> | ProcessPendingIntegrationCallsRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'integrationTaskId': unwrapped.integrationTaskId,
      'sourceAppSystemId': unwrapped.sourceAppSystemId,
      'itemsToBeProcessed': unwrapped.itemsToBeProcessed,
    } as ProcessPendingIntegrationCallsRequestDTO;
  }
}
