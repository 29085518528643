// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetCampaignFieldMappingsRequestModel from '../../model/get-campaign-field-mappings-request-model';
import GetCampaignFieldMappingsRequestDTO from '../dto/get-campaign-field-mappings-request-dto';

export default abstract class GetCampaignFieldMappingsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetCampaignFieldMappingsRequestDTO>) {
    super();
    if (data) {
      this.appSystemId = data.appSystemId;
      this.fieldId = data.fieldId;
      this.fieldValue = data.fieldValue;
      this.otherAppSystemId = data.otherAppSystemId;
      this['paging.CurrentPage'] = data['paging.CurrentPage'];
      this['paging.PageSize'] = data['paging.PageSize'];
      this['paging.IncludeCount'] = data['paging.IncludeCount'];
      this['paging.NoPaging'] = data['paging.NoPaging'];
    }
  }
  /**
  * @type {int32}
  */
  'appSystemId'?: number;
  /**
  */
  'fieldId'?: string;
  /**
  */
  'fieldValue'?: string;
  /**
  * @type {int32}
  */
  'otherAppSystemId'?: number;
  /**
  * @type {int32}
  */
  'paging.CurrentPage'?: number;
  /**
  * @type {int32}
  */
  'paging.PageSize'?: number;
  /**
  */
  'paging.IncludeCount'?: boolean;
  /**
  */
  'paging.NoPaging'?: boolean;

  static toModel(dto: DeepPartial<GetCampaignFieldMappingsRequestDTO>): GetCampaignFieldMappingsRequestModel;
  static toModel(dto: DeepPartial<GetCampaignFieldMappingsRequestDTO> | undefined | null): GetCampaignFieldMappingsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetCampaignFieldMappingsRequestDTO> | undefined | null): GetCampaignFieldMappingsRequestModel | undefined {
    return dto ? new GetCampaignFieldMappingsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetCampaignFieldMappingsRequestModel> | GetCampaignFieldMappingsRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'appSystemId': unwrapped.appSystemId,
      'fieldId': unwrapped.fieldId,
      'fieldValue': unwrapped.fieldValue,
      'otherAppSystemId': unwrapped.otherAppSystemId,
      'paging.CurrentPage': unwrapped['paging.CurrentPage'],
      'paging.PageSize': unwrapped['paging.PageSize'],
      'paging.IncludeCount': unwrapped['paging.IncludeCount'],
      'paging.NoPaging': unwrapped['paging.NoPaging'],
    } as GetCampaignFieldMappingsRequestDTO;
  }
}
