// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ProductModel from '../../model/product-model';
import ProductDTO from '../dto/product-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ProductModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ProductDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.articleNumber = data.articleNumber ?? undefined;
      this.title = data.title ?? undefined;
      this.text = data.text ?? undefined;
      this.salePrice = data.salePrice;
      this.publisher = data.publisher ?? undefined;
      this.publishDate = data.publishDate;
      this.subTitles = data.subTitles ?? undefined;
      this.length = data.length ?? undefined;
      this.width = data.width ?? undefined;
      this.heigth = data.heigth ?? undefined;
      this.orderColor = data.orderColor;
      this.isActive = data.isActive;
      this.fdsTitle = data.fdsTitle ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  */
  'creatorName'?: string;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  */
  'modifierName'?: string;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  */
  'articleNumber'?: string;
  /**
  */
  'title'?: string;
  /**
  */
  'text'?: string;
  /**
  * @type {double}
  */
  'salePrice'?: number;
  /**
  */
  'publisher'?: string;
  /**
  * @type {date-time}
  */
  'publishDate'?: string;
  /**
  */
  'subTitles'?: string[];
  /**
  * @type {int32}
  */
  'length'?: number;
  /**
  * @type {int32}
  */
  'width'?: number;
  /**
  * @type {int32}
  */
  'heigth'?: number;
  /**
  * @type {int32}
  */
  'orderColor'?: number;
  /**
  */
  'isActive'?: boolean;
  /**
  */
  'fdsTitle'?: string;

  static toModel(dto: DeepPartial<ProductDTO>): ProductModel;
  static toModel(dto: DeepPartial<ProductDTO> | undefined | null): ProductModel | undefined;
  static toModel(dto: DeepPartial<ProductDTO> | undefined | null): ProductModel | undefined {
    return dto ? new ProductModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ProductModel> | ProductModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'creatorId': unwrapped.creatorId,
      'creatorName': unwrapped.creatorName,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modifierName': unwrapped.modifierName,
      'modified': unwrapped.modified,
      'articleNumber': unwrapped.articleNumber,
      'title': unwrapped.title,
      'text': unwrapped.text,
      'salePrice': unwrapped.salePrice,
      'publisher': unwrapped.publisher,
      'publishDate': unwrapped.publishDate,
      'subTitles': unwrapped.subTitles,
      'length': unwrapped.length,
      'width': unwrapped.width,
      'heigth': unwrapped.heigth,
      'orderColor': unwrapped.orderColor,
      'isActive': unwrapped.isActive,
      'fdsTitle': unwrapped.fdsTitle,
    } as ProductDTO;
  }
}
