// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetMeRequestModel from '../../model/get-me-request-model';
import GetMeRequestDTO from '../dto/get-me-request-dto';

export default abstract class GetMeRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetMeRequestDTO>) {
    super();
  }

  static toModel(dto: DeepPartial<GetMeRequestDTO>): GetMeRequestModel;
  static toModel(dto: DeepPartial<GetMeRequestDTO> | undefined | null): GetMeRequestModel | undefined;
  static toModel(dto: DeepPartial<GetMeRequestDTO> | undefined | null): GetMeRequestModel | undefined {
    return dto ? new GetMeRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetMeRequestModel> | GetMeRequestModel) {
    return {
    } as GetMeRequestDTO;
  }
}
