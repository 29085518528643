/* eslint-disable quote-props */

import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import OrderItemArticleModel from './order-item-article-model';

export default class OrderItemArticleListResultModel extends ServiceModelBase {
  constructor(data?: DeepPartial<OrderItemArticleListResultModel>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => new OrderItemArticleModel(i)) ?? [];
      this.links = data.links?.map((i) => i) ?? [];
    }
  }
  /**
  */
  'items': OrderItemArticleModel[];
  /**
  */
  'links': undefined[];
  static toModel(dto: DeepPartial<OrderItemArticleModel>): OrderItemArticleModel;
  static toModel(dto: DeepPartial<OrderItemArticleModel> | undefined | null): OrderItemArticleModel | undefined;
  static toModel(dto: DeepPartial<OrderItemArticleModel> | undefined | null): OrderItemArticleModel | undefined {
    return dto ? new OrderItemArticleModel(dto) : undefined;
  }
}
