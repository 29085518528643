// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import IntegrationCallStepModel from '../../model/integration-call-step-model';
import IntegrationCallStepDTO from '../dto/integration-call-step-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class IntegrationCallStepModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<IntegrationCallStepDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.integrationCallId = data.integrationCallId;
      this.targetAppSystemId = data.targetAppSystemId;
      this.targetAppSystemName = data.targetAppSystemName ?? undefined;
      this.name = data.name ?? undefined;
      this.number = data.number;
      this.isSuccess = data.isSuccess;
      this.isOperative = data.isOperative;
      this.message = data.message ?? undefined;
      this.runNo = data.runNo;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  */
  'creatorName'?: string;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  */
  'modifierName'?: string;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  * @type {int64}
  */
  'integrationCallId'?: number;
  /**
  * @type {int64}
  */
  'targetAppSystemId'?: number;
  /**
  */
  'targetAppSystemName'?: string;
  /**
  */
  'name'?: string;
  /**
  * @type {int32}
  */
  'number'?: number;
  /**
  */
  'isSuccess'?: boolean;
  /**
  */
  'isOperative'?: boolean;
  /**
  */
  'message'?: string;
  /**
  * @type {int32}
  */
  'runNo'?: number;

  static toModel(dto: DeepPartial<IntegrationCallStepDTO>): IntegrationCallStepModel;
  static toModel(dto: DeepPartial<IntegrationCallStepDTO> | undefined | null): IntegrationCallStepModel | undefined;
  static toModel(dto: DeepPartial<IntegrationCallStepDTO> | undefined | null): IntegrationCallStepModel | undefined {
    return dto ? new IntegrationCallStepModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<IntegrationCallStepModel> | IntegrationCallStepModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'creatorId': unwrapped.creatorId,
      'creatorName': unwrapped.creatorName,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modifierName': unwrapped.modifierName,
      'modified': unwrapped.modified,
      'integrationCallId': unwrapped.integrationCallId,
      'targetAppSystemId': unwrapped.targetAppSystemId,
      'targetAppSystemName': unwrapped.targetAppSystemName,
      'name': unwrapped.name,
      'number': unwrapped.number,
      'isSuccess': unwrapped.isSuccess,
      'isOperative': unwrapped.isOperative,
      'message': unwrapped.message,
      'runNo': unwrapped.runNo,
    } as IntegrationCallStepDTO;
  }
}
