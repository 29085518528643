import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import AuthenticationStrategy from '@glittr/frontend-core/src/plugins/auth/authenticationStrategy';
import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import ChangePasswordRequestModel from '../../model/change-password-request-model';
import ForgotPasswordRequestModel from '../../model/forgot-password-request-model';
import GetUserByIdRequestModel from '../../model/get-user-by-id-request-model';
import GetUsersRequestModel from '../../model/get-users-request-model';
import RegisterRequestModel from '../../model/register-request-model';
import SetUserAppRoleRequestModel from '../../model/set-user-app-role-request-model';
import SetUserStatusRequestModel from '../../model/set-user-status-request-model';
import UpdateUserRequestModel from '../../model/update-user-request-model';
import usersServices from '../../_generated/api/users';

export default (service: Servicelayer, log: Logger, authService: AuthenticationStrategy) => {
  const baseService = usersServices(service);

  return {
    async getUser(isOnline: boolean) {
      if (!authService.user) {
        const ret = await authService.getUser();
        return ret;
      }
      return authService.user;
    },
    async register(request: RegisterRequestModel, config?: RequestConfig): Promise<DataRecord<undefined> | DataRecord<number>> {
      return baseService.register(request, config);
    },
    async forgotPassword(request: ForgotPasswordRequestModel, config?: RequestConfig) {
      return baseService.forgotPassword(request, config);
    },
    async changePassword(request: ChangePasswordRequestModel, config?: RequestConfig) {
      return baseService.changePassword(request, config);
    },
    async getUsers(isOnline: boolean, request: GetUsersRequestModel, config?: RequestConfig) {
      const ret = await baseService.getUsers(request, config);
      return ret;
    },
    async getUserById(request: GetUserByIdRequestModel, config?: RequestConfig) {
      return baseService.getUserById(request, config);
    },
    async updateUser(request: UpdateUserRequestModel, config?: RequestConfig) {
      return baseService.updateUser(request, config);
    },
    async setUserStatus(request: SetUserStatusRequestModel, config?: RequestConfig) {
      return baseService.setUserStatus(request, config);
    },
    async setUserAppRole(request: SetUserAppRoleRequestModel, config?: RequestConfig) {
      return baseService.setUserAppRole(request, config);
    },
  };
};
