



















































































































































































import Vue from 'vue';
import CustomerOrderEditViewModel from './customer-order-edit.vue.model';

export default Vue.extend({
  components: {
  },
  auth: true,
  data: () => ({
    vm: new CustomerOrderEditViewModel(),
    customerDataExpanded: true,
  }),
  async beforeDestroy() { await this.vm.saveAndClose(); },
  mounted() {
    this.vm.initialize();
  },
});
