import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import Vue from 'vue';
import CategoryOrderItemArticleListResultModel from '../../../../services/v2/model/category-order-item-article-model';

export default class ProductListEditViewModel extends PageBase {
  productColors: string[] = ['rgba(255,255,255,0)', 'rgba(255,255,0,0.3)', 'rgba(0,255,0,0.3)'];
  products: CategoryOrderItemArticleListResultModel[] = [];
  isLoading = true;
  async initialize(): Promise<void> {
    try {
      const productCategory = (await Vue.$service.v2.api.productCategories.getProductCategories(Vue.$pwa.isOnline, {}, undefined)).items.map((d) => d.data).filter((c) => c.isRoot)[0];
      this.products = await Vue.$service.v2.api.products.getProductsForOrderGroupedByCategories(Vue.$pwa.isOnline, {}, undefined, undefined, productCategory?.id, true);
    } catch (error: any) {
      Vue.$alert.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
