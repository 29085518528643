import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import Vue from 'vue';
import CustomerOrderDTO from '../../../../services/v2/_generated/dto/customer-order-dto';
import GetCustomerOrderListRequestModel from '../../../../services/v2/model/get-customer-order-list-request-model';
import DownloadAbaFileRequestModel from '../../../../services/v2/model/download-aba-file-request-model';
import CustomerOrderListPagingResultModel from '../../../../services/v2/model/customer-order-list-paging-result-model';

export default class DataDownloadViewModel extends PageBase {
  async applyFilter() {
    this.customerOrdersDataSource.filter = this.filter;
    await this.customerOrdersDataSource.select();
  }
  filter: GetCustomerOrderListRequestModel = {
    orderState: 2,
  };

  downloadErrorInfo = undefined as String | undefined;
  listEntries = undefined;
  isDownloading = false;
  selectedOrders = [] as CustomerOrderDTO[];
  orderStatusValues = [
    // Kein Status { caption: Vue.$tEnum('page.customerOrderList.label.orderStates', 0), detail: '', id: 0 },
    { caption: Vue.$tEnum('page.customerOrderList.label.orderStates', 1), detail: '', id: 1 },
    { caption: Vue.$tEnum('page.customerOrderList.label.orderStates', 2), detail: '', id: 2 },
    { caption: Vue.$tEnum('page.customerOrderList.label.orderStates', 3), detail: '', id: 3 },
    { caption: Vue.$tEnum('page.customerOrderList.label.orderStates', 4), detail: '', id: 4 },
  ];
  columns = [] as {}[];

  async initialize(): Promise<void> {
    await this.customerOrdersDataSource.initialize();
  }

  customerOrdersDataSource = new DataSource({
    selectCommand: (request: GetCustomerOrderListRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<CustomerOrderListPagingResultModel>> => Vue.$service.v2.api.customerOrders.getCustomerOrderList(Vue.$pwa.isOnline, request, config),
  });

  canDownload() {
    return (this.customerOrdersDataSource.data?.items ?? []).length > 0 && this.customerOrdersDataSource.data?.items.some((x) => x.isSelected);
  }
  async onOrderDownloadSelectedClicked() {
    this.customerOrdersDataSource.data?.items.filter((x) => x.isSelected).forEach(async (o) => {
      await this.onOrderDownloadClicked(o.data);
      o.isSelected = false;
    });
  }
  async onOrderDownloadClicked(order: any) {
    this.isDownloading = true;
    const req = new DownloadAbaFileRequestModel();
    req.orderId = order.id;

    try {
      const response = await Vue.$service.v2.api.customerOrders.downloadAbaFile(Vue.$pwa.isOnline, req);
      Vue.$log.debug(response);
      const now = Vue.$date.now().format('YYYYMMDD-HHmmss');
      Vue.$service.utils.downloadFile(`${now}-BVG-Abacus-Bestellung-${order.id}.xml`, response.data);
    } catch (error: any) {
      Vue.$alert.error(error);
    } finally {
      this.isDownloading = false;
    }
  }
  setDownloadInfo() {
    this.downloadErrorInfo = undefined;
    this.customerOrdersDataSource.filter.orderState = 4;
    this.customerOrdersDataSource.select();
    const count = this.customerOrdersDataSource.data?.items.length;
    if (count as number > 0) {
      this.downloadErrorInfo = Vue.$t('page.dataDownload.label.abaDownloadErrorInfo', count);
    }
  }
}
