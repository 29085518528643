// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ChangePasswordRequestModel from '../../model/change-password-request-model';
import ChangePasswordRequestDTO from '../dto/change-password-request-dto';

export default abstract class ChangePasswordRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ChangePasswordRequestDTO>) {
    super();
    if (data) {
      this.token = data.token;
      this.newPassword = data.newPassword;
    }
  }
  /**
  */
  'token'?: string;
  /**
  */
  'newPassword'?: string;

  static toModel(dto: DeepPartial<ChangePasswordRequestDTO>): ChangePasswordRequestModel;
  static toModel(dto: DeepPartial<ChangePasswordRequestDTO> | undefined | null): ChangePasswordRequestModel | undefined;
  static toModel(dto: DeepPartial<ChangePasswordRequestDTO> | undefined | null): ChangePasswordRequestModel | undefined {
    return dto ? new ChangePasswordRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ChangePasswordRequestModel> | ChangePasswordRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'token': unwrapped.token,
      'newPassword': unwrapped.newPassword,
    } as ChangePasswordRequestDTO;
  }
}
