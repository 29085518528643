// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormUploadDocumentRequestModel from '../../model/dynamic-form-upload-document-request-model';
import DynamicFormUploadDocumentRequestDTO from '../dto/dynamic-form-upload-document-request-dto';

export default abstract class DynamicFormUploadDocumentRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormUploadDocumentRequestDTO>) {
    super();
    if (data) {
      this.uploadFile = data.uploadFile;
    }
  }
  /**
  * @type {binary}
  */
  'uploadFile'?: File;

  static toModel(dto: DeepPartial<DynamicFormUploadDocumentRequestDTO>): DynamicFormUploadDocumentRequestModel;
  static toModel(dto: DeepPartial<DynamicFormUploadDocumentRequestDTO> | undefined | null): DynamicFormUploadDocumentRequestModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormUploadDocumentRequestDTO> | undefined | null): DynamicFormUploadDocumentRequestModel | undefined {
    return dto ? new DynamicFormUploadDocumentRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormUploadDocumentRequestModel> | DynamicFormUploadDocumentRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'uploadFile': unwrapped.uploadFile,
    } as DynamicFormUploadDocumentRequestDTO;
  }
}
