















































































import Vue from 'vue';
import OrderItemArticleModel from '../../services/v2/model/order-item-article-model';
import ProductListEntryViewModel from './bv-product-list-entry.vue.model';

export default Vue.extend({
  components: {
    bvProductImage: () => import(/* webpackPrefetch: true */ './bv-product-image.vue'),
  },
  props: {
    value: { type: Object, default: () => new OrderItemArticleModel() },
    isReadOnly: { type: Boolean, default: true },
    isShowQuantity: { type: Boolean, default: false },
  },
  data: () => ({
    vm: new ProductListEntryViewModel(),
  }),
  mounted() {
    this.vm.initialize();
    this.vm.onSubtitleSelected.addEventListener((selected) => this.$emit('onSelectedSubtitlesChanged', selected));
  },
});
