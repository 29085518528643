




import Vue from 'vue';

export default Vue.extend({
  layout: 'layout-exterior',
  beforeMount() {
    this.$router.push('/bv/ordering-app').catch(() => {});
  },
});
