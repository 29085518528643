// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetProductCategoriesRequestModel from '../../model/get-product-categories-request-model';
import GetProductCategoriesRequestDTO from '../dto/get-product-categories-request-dto';

export default abstract class GetProductCategoriesRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetProductCategoriesRequestDTO>) {
    super();
    if (data) {
      this.searchCriteria = data.searchCriteria;
    }
  }
  /**
  */
  'searchCriteria'?: string;

  static toModel(dto: DeepPartial<GetProductCategoriesRequestDTO>): GetProductCategoriesRequestModel;
  static toModel(dto: DeepPartial<GetProductCategoriesRequestDTO> | undefined | null): GetProductCategoriesRequestModel | undefined;
  static toModel(dto: DeepPartial<GetProductCategoriesRequestDTO> | undefined | null): GetProductCategoriesRequestModel | undefined {
    return dto ? new GetProductCategoriesRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetProductCategoriesRequestModel> | GetProductCategoriesRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'searchCriteria': unwrapped.searchCriteria,
    } as GetProductCategoriesRequestDTO;
  }
}
