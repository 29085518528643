// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UploadDocumentRequestModel from '../../model/upload-document-request-model';
import UploadDocumentRequestDTO from '../dto/upload-document-request-dto';

export default abstract class UploadDocumentRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UploadDocumentRequestDTO>) {
    super();
    if (data) {
      this.uploadFile = data.uploadFile;
      this.documentsPath = data.documentsPath;
    }
  }
  /**
  * @type {binary}
  */
  'uploadFile'?: File;
  /**
  */
  'documentsPath'?: string;

  static toModel(dto: DeepPartial<UploadDocumentRequestDTO>): UploadDocumentRequestModel;
  static toModel(dto: DeepPartial<UploadDocumentRequestDTO> | undefined | null): UploadDocumentRequestModel | undefined;
  static toModel(dto: DeepPartial<UploadDocumentRequestDTO> | undefined | null): UploadDocumentRequestModel | undefined {
    return dto ? new UploadDocumentRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UploadDocumentRequestModel> | UploadDocumentRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'uploadFile': unwrapped.uploadFile,
      'documentsPath': unwrapped.documentsPath,
    } as UploadDocumentRequestDTO;
  }
}
