// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ResourceLinkModel from '../../model/resource-link-model';
import ResourceLinkDTO from '../dto/resource-link-dto';

export default abstract class ResourceLinkModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ResourceLinkDTO>) {
    super();
    if (data) {
      this.href = data.href ?? undefined;
      this.rel = data.rel ?? undefined;
      this.method = data.method ?? undefined;
    }
  }
  /**
  * @readonly
  */
  'href'?: string;
  /**
  * @readonly
  */
  'rel'?: string;
  /**
  * @readonly
  */
  'method'?: string;

  static toModel(dto: DeepPartial<ResourceLinkDTO>): ResourceLinkModel;
  static toModel(dto: DeepPartial<ResourceLinkDTO> | undefined | null): ResourceLinkModel | undefined;
  static toModel(dto: DeepPartial<ResourceLinkDTO> | undefined | null): ResourceLinkModel | undefined {
    return dto ? new ResourceLinkModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ResourceLinkModel> | ResourceLinkModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'href': unwrapped.href,
      'rel': unwrapped.rel,
      'method': unwrapped.method,
    } as ResourceLinkDTO;
  }
}
