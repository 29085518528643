






























































import Vue from 'vue';
import DataUploadViewModel from './data-upload.vue.model';

export default Vue.extend({
  auth: true,
  data: () => ({
    vm: new DataUploadViewModel(),
  }),
});
