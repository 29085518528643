// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormPayloadModel from '../../model/dynamic-form-payload-model';
import DynamicFormPayloadDTO from '../dto/dynamic-form-payload-dto';
import DynamicFormContactModel from '../../model/dynamic-form-contact-model';
import DynamicFormFieldPayloadModel from '../../model/dynamic-form-field-payload-model';

export default abstract class DynamicFormPayloadModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormPayloadDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.definitionId = data.definitionId ?? undefined;
      this.contactId = data.contactId ?? undefined;
      this.contact = data.contact ? DynamicFormContactModel.toModel(data.contact) : undefined;
      this.languageIso2 = data.languageIso2 ?? undefined;
      this.fields = data.fields?.map((i) => DynamicFormFieldPayloadModel.toModel(i)) ?? [];
      this.transactionId = data.transactionId ?? undefined;
    }
  }
  /**
  */
  'id'?: string;
  /**
  */
  'definitionId'?: string;
  /**
  */
  'contactId'?: string;
  /**
  */
  'contact'?: DynamicFormContactModel;
  /**
  */
  'languageIso2'?: string;
  /**
  */
  'fields'?: DynamicFormFieldPayloadModel[];
  /**
  */
  'transactionId'?: string;

  static toModel(dto: DeepPartial<DynamicFormPayloadDTO>): DynamicFormPayloadModel;
  static toModel(dto: DeepPartial<DynamicFormPayloadDTO> | undefined | null): DynamicFormPayloadModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormPayloadDTO> | undefined | null): DynamicFormPayloadModel | undefined {
    return dto ? new DynamicFormPayloadModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormPayloadModel> | DynamicFormPayloadModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'definitionId': unwrapped.definitionId,
      'contactId': unwrapped.contactId,
      'contact': unwrapped.contact,
      'languageIso2': unwrapped.languageIso2,
      'fields': unwrapped.fields,
      'transactionId': unwrapped.transactionId,
    } as DynamicFormPayloadDTO;
  }
}
