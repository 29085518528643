// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PostCustomerOrderRequestModel from '../../model/post-customer-order-request-model';
import PostCustomerOrderRequestDTO from '../dto/post-customer-order-request-dto';
import ResourceLinkModel from '../../model/resource-link-model';
import CustomerOrderItemModel from '../../model/customer-order-item-model';

export default abstract class PostCustomerOrderRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PostCustomerOrderRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.employeeId = data.employeeId;
      this.customerId = data.customerId;
      this.orderDate = data.orderDate;
      this.deliveryDate = data.deliveryDate;
      this.orderItems = data.orderItems?.map((i) => CustomerOrderItemModel.toModel(i)) ?? [];
      this.comment = data.comment ?? undefined;
      this.orderType = data.orderType ?? undefined;
      this.orderState = data.orderState;
      this.orderSentState = data.orderSentState ?? undefined;
      this.orderSentMessage = data.orderSentMessage ?? undefined;
      this.orderSentAt = data.orderSentAt ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  */
  'creatorName'?: string;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  */
  'modifierName'?: string;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  * @type {int64}
  */
  'employeeId'?: number;
  /**
  * @type {int64}
  */
  'customerId'?: number;
  /**
  * @type {date-time}
  */
  'orderDate'?: string;
  /**
  * @type {date-time}
  */
  'deliveryDate'?: string;
  /**
  */
  'orderItems'?: CustomerOrderItemModel[];
  /**
  */
  'comment'?: string;
  /**
  */
  'orderType'?: string;
  /**
  * @type {int32}
  */
  'orderState'?: 0 | 1 | 2 | 3 | 4; // OrderStateDTO
  /**
  */
  'orderSentState'?: string;
  /**
  */
  'orderSentMessage'?: string;
  /**
  * @type {date-time}
  */
  'orderSentAt'?: string;

  static toModel(dto: DeepPartial<PostCustomerOrderRequestDTO>): PostCustomerOrderRequestModel;
  static toModel(dto: DeepPartial<PostCustomerOrderRequestDTO> | undefined | null): PostCustomerOrderRequestModel | undefined;
  static toModel(dto: DeepPartial<PostCustomerOrderRequestDTO> | undefined | null): PostCustomerOrderRequestModel | undefined {
    return dto ? new PostCustomerOrderRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PostCustomerOrderRequestModel> | PostCustomerOrderRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'creatorId': unwrapped.creatorId,
      'creatorName': unwrapped.creatorName,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modifierName': unwrapped.modifierName,
      'modified': unwrapped.modified,
      'employeeId': unwrapped.employeeId,
      'customerId': unwrapped.customerId,
      'orderDate': unwrapped.orderDate,
      'deliveryDate': unwrapped.deliveryDate,
      'orderItems': unwrapped.orderItems,
      'comment': unwrapped.comment,
      'orderType': unwrapped.orderType,
      'orderState': unwrapped.orderState,
      'orderSentState': unwrapped.orderSentState,
      'orderSentMessage': unwrapped.orderSentMessage,
      'orderSentAt': unwrapped.orderSentAt,
    } as PostCustomerOrderRequestDTO;
  }
}
