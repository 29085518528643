




import Vue from 'vue';
import GetEmployeeByUserIdRequestModel from '../../../../services/v2/model/get-employee-by-user-id-request-model';

export default Vue.extend({
  auth: true,
  data: () => ({
  }),
  async beforeMount() {
    try {
      const user = await Vue.$service.v2.api.users.getUser(Vue.$pwa.isOnline);
      const employee = await Vue.$service.v2.api.employees.getEmployeeByUserId(Vue.$pwa.isOnline, GetEmployeeByUserIdRequestModel.toModel({ userId: user?.id! }));
      const employeeId = employee.data.id;
      Vue.$router.replace(`/bv/ordering-app/customer-order/edit/${Vue.$service.v2.api.customerOrders.createLocalCustomerOrder(employeeId!)}`);
    } catch (error: any) {
      this.$alert.error(error);
    }
  },
});
