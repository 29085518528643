import DataRecord, { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import dateFactory from '@glittr/frontend-core/src/plugins/localization/date';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import type Dayjs from 'dayjs';
import CustomerOrderListPagingResultModel from '../../model/customer-order-list-paging-result-model';
import CustomerOrderModel from '../../model/customer-order-model';
import DownloadAbaFileRequestModel from '../../model/download-aba-file-request-model';
import GetCustomerOrderListRequestModel from '../../model/get-customer-order-list-request-model';
import GetCustomerOrdersByIdRequestModel from '../../model/get-customer-orders-by-id-request-model';
import PostCustomerOrderRequestModel from '../../model/post-customer-order-request-model';
import customerOrdersServices from '../../_generated/api/customer-orders';
import customersServices from '../../_generated/api/customers';
import CustomerOrderListModel from '../../model/customer-order-list-model';
import Cache from './cache';
import StorageTypes from './storage-types';
import CustomerOrderListDTO from '../../_generated/dto/customer-order-list-dto';
import CustomerOrderListPagingResultDTO from '../../_generated/dto/customer-order-list-paging-result-dto';
import PostCustomerOrderRequestDTO from '../../_generated/dto/post-customer-order-request-dto';
import CustomerOrderDTO from '../../_generated/dto/customer-order-dto';

export default ($date: typeof Dayjs, service: Servicelayer, log: Logger, date: ReturnType<typeof dateFactory>) => {
  const wrapResultWithDataRecord = <T>(res: T): DataRecordWrapResponse<T> => {
    let clone = JSON.parse(JSON.stringify(res));
    if (Array.isArray(clone?.items)) clone.items = clone.items.map((i: any) => new DataRecord(i));
    else clone = new DataRecord(clone);
    return clone;
  };

  const cacheCustomerOrders = new Cache<CustomerOrderModel>(StorageTypes.CustomerOrders);
  const baseService = customerOrdersServices(service);
  const fSort = (a: CustomerOrderListModel, b: CustomerOrderListModel): number => {
    let Dx = a.orderState! - b.orderState!; // 1st criteria
    if (Dx === 0) Dx = a.orderDate!.localeCompare(b.orderDate!); // 2nd criteria
    if (Dx > 0) {
      return 1;
    } if (Dx < 0) {
      return -1;
    }
    return 0;
  };

  return {
    updateLocalOrder(item: CustomerOrderModel): void {
      cacheCustomerOrders.update(item);
    },

    async getCustomerLocalOrderList(): Promise<DataRecordWrapResponse<CustomerOrderListPagingResultModel>> {
      const customerService = customersServices(service);
      const customers = await customerService.getAllCustomers({}, undefined);
      const items = cacheCustomerOrders.readAll()
        .filter((x: CustomerOrderModel) => x.orderState === 1)
        .map((x: CustomerOrderModel) => {
          const customer = customers.items.find((c) => c.data.id === x.customerId)?.data;
          return new CustomerOrderListModel({
            ...x,
            orderItemsCount: (x.orderItems?.filter((x) => (x.quantity ?? 0) > 0) || []).length,
            comment: x.comment,
            customerNumber: customer?.customerNumber,
            customerShortName: customer ? `${customer?.companyShortName}` : undefined,
            customerName: customer?.companyName,
          } as CustomerOrderListDTO);
        })
        .sort((a, b) => fSort(a, b));
      const cachedResults = wrapResultWithDataRecord(CustomerOrderListPagingResultModel.toModel({
        items,
      } as DeepPartial<CustomerOrderListPagingResultDTO>));
      return cachedResults;
    },
    async getCustomerOrderList(isOnline: boolean, request: GetCustomerOrderListRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<CustomerOrderListPagingResultModel>> {
      if (!isOnline) { request = {}; config = {}; }
      const ret = await baseService.getCustomerOrderList(request, config);
      ret.items = ret.items.sort((x) => new Date(x.data.orderSentAt!).getTime());
      if (!isOnline) ret.items = ret.items.filter((x) => $date(new Date()).subtract(2, 'days').isAfter($date(x.data.orderDate)));
      return ret;
    },
    async postCustomerOrder(isOnline: boolean, localOrderId: number): Promise<DataRecordWrapResponse<CustomerOrderModel> | DataRecordWrapResponse<undefined>> {
      const localOrder = cacheCustomerOrders.read(localOrderId);
      if (localOrder) {
        const item = { ...localOrder };
        const ret = await baseService.postCustomerOrder(PostCustomerOrderRequestModel.toModel({ ...item } as DeepPartial<PostCustomerOrderRequestDTO>), undefined);
        if ((ret.data.id ?? 0) > 0) {
          cacheCustomerOrders.remove(localOrder);
          return this.getCustomerOrdersById(isOnline, GetCustomerOrdersByIdRequestModel.toModel({ id: ret.data.id }), undefined, true);
        }
      }
      return Promise.resolve(new DataRecord(undefined));
    },

    async getCustomerOrdersById(isOnline: boolean, request: GetCustomerOrdersByIdRequestModel, config?: RequestConfig, doForceServerRequest: boolean = false): Promise<DataRecord<CustomerOrderModel>> {
      const item = cacheCustomerOrders.readAll().filter((x) => x.id === request.id)[0];
      if (!doForceServerRequest && (!isOnline || item)) {
        return Promise.resolve(wrapResultWithDataRecord(item));
      }
      const ret = await baseService.getCustomerOrdersById(request, config);
      cacheCustomerOrders.update(ret.data);
      return ret || item;
    },
    async downloadAbaFile(isOnline: boolean, request: DownloadAbaFileRequestModel, config?: RequestConfig) {
      log.error('downloadAbaFile is not cached');
      return baseService.downloadAbaFile(request, config);
    },
    createLocalCustomerOrder(employeeId: number): number {
      const id = new Date().getTime();
      const item = new CustomerOrderModel({
        orderDate: date.now().format('YYYY-MM-DD'),
        orderState: 1,
        orderType: '0',
        id,
        employeeId,
        orderItems: [],
        creatorId: 0,
        customerId: 0,
        deliveryDate: undefined,
      } as DeepPartial<CustomerOrderDTO>);
      cacheCustomerOrders.update(item);
      return id;
    },
    deleteLocalCustomerOrderById(orderId: number) {
      cacheCustomerOrders.removeById(orderId);
    },
  };
};
