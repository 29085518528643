// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetTokenRequestModel from '../../model/get-token-request-model';
import GetTokenRequestDTO from '../dto/get-token-request-dto';

export default abstract class GetTokenRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetTokenRequestDTO>) {
    super();
    if (data) {
      this.userName = data.userName ?? undefined;
      this.password = data.password ?? undefined;
      this.shareId = data.shareId ?? undefined;
    }
  }
  /**
  */
  'userName'?: string;
  /**
  */
  'password'?: string;
  /**
  */
  'shareId'?: string;

  static toModel(dto: DeepPartial<GetTokenRequestDTO>): GetTokenRequestModel;
  static toModel(dto: DeepPartial<GetTokenRequestDTO> | undefined | null): GetTokenRequestModel | undefined;
  static toModel(dto: DeepPartial<GetTokenRequestDTO> | undefined | null): GetTokenRequestModel | undefined {
    return dto ? new GetTokenRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetTokenRequestModel> | GetTokenRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'userName': unwrapped.userName,
      'password': unwrapped.password,
      'shareId': unwrapped.shareId,
    } as GetTokenRequestDTO;
  }
}
