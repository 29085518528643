// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import RefreshTokenRequestModel from '../../model/refresh-token-request-model';
import RefreshTokenRequestDTO from '../dto/refresh-token-request-dto';

export default abstract class RefreshTokenRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<RefreshTokenRequestDTO>) {
    super();
    if (data) {
      this.accessToken = data.accessToken;
      this.refreshToken = data.refreshToken;
    }
  }
  /**
  */
  'accessToken'?: string;
  /**
  */
  'refreshToken'?: string;

  static toModel(dto: DeepPartial<RefreshTokenRequestDTO>): RefreshTokenRequestModel;
  static toModel(dto: DeepPartial<RefreshTokenRequestDTO> | undefined | null): RefreshTokenRequestModel | undefined;
  static toModel(dto: DeepPartial<RefreshTokenRequestDTO> | undefined | null): RefreshTokenRequestModel | undefined {
    return dto ? new RefreshTokenRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<RefreshTokenRequestModel> | RefreshTokenRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'accessToken': unwrapped.accessToken,
      'refreshToken': unwrapped.refreshToken,
    } as RefreshTokenRequestDTO;
  }
}
