// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LookupCustomersRequestModel from '../../model/lookup-customers-request-model';
import LookupCustomersRequestDTO from '../dto/lookup-customers-request-dto';

export default abstract class LookupCustomersRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LookupCustomersRequestDTO>) {
    super();
    if (data) {
      this.employeeId = data.employeeId;
      this.id = data.id;
      this.key = data.key;
      this.searchCriteria = data.searchCriteria;
      this['paging.CurrentPage'] = data['paging.CurrentPage'];
      this['paging.PageSize'] = data['paging.PageSize'];
      this['paging.IncludeCount'] = data['paging.IncludeCount'];
      this['paging.NoPaging'] = data['paging.NoPaging'];
    }
  }
  /**
  * @type {int64}
  */
  'employeeId'?: number;
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'key'?: string;
  /**
  */
  'searchCriteria'?: string;
  /**
  * @type {int32}
  */
  'paging.CurrentPage'?: number;
  /**
  * @type {int32}
  */
  'paging.PageSize'?: number;
  /**
  */
  'paging.IncludeCount'?: boolean;
  /**
  */
  'paging.NoPaging'?: boolean;

  static toModel(dto: DeepPartial<LookupCustomersRequestDTO>): LookupCustomersRequestModel;
  static toModel(dto: DeepPartial<LookupCustomersRequestDTO> | undefined | null): LookupCustomersRequestModel | undefined;
  static toModel(dto: DeepPartial<LookupCustomersRequestDTO> | undefined | null): LookupCustomersRequestModel | undefined {
    return dto ? new LookupCustomersRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LookupCustomersRequestModel> | LookupCustomersRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'employeeId': unwrapped.employeeId,
      'id': unwrapped.id,
      'key': unwrapped.key,
      'searchCriteria': unwrapped.searchCriteria,
      'paging.CurrentPage': unwrapped['paging.CurrentPage'],
      'paging.PageSize': unwrapped['paging.PageSize'],
      'paging.IncludeCount': unwrapped['paging.IncludeCount'],
      'paging.NoPaging': unwrapped['paging.NoPaging'],
    } as LookupCustomersRequestDTO;
  }
}
