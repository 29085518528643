import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/customer-order/customer-order-list.vue'),
    },
    {
      path: '/:tenant/:workspace/settings/data-upload',
      name: 'settings-data-upload',
      component: () => import('./views/pages/app/settings/data-upload.vue'),
    },
    {
      path: '/:tenant/:workspace/settings/data-download',
      name: 'settings-data-download',
      component: () => import('./views/pages/app/settings/data-download.vue'),
    },
    {
      path: '/:tenant/:workspace/settings/offline-caching',
      name: 'settings-offline-caching',
      component: () => import('./views/pages/app/settings/offline-caching.vue'),
    },
    {
      path: '/:tenant/:workspace/customer-order-overview',
      name: 'customer-order-overview',
      component: () => import('./views/pages/app/customer-order/customer-order-list.vue'),
    },

    {
      path: '/:tenant/:workspace/customer-order/edit/:order',
      name: 'customer-order-edit',
      component: () => import('./views/pages/app/customer-order/customer-order-edit.vue'),
    },
    {
      path: '/:tenant/:workspace/customer-order/new',
      name: 'customer-order-new',
      component: () => import('./views/pages/app/customer-order/customer-order-new.vue'),
    },
    {
      path: '/:tenant/:workspace/products/product-list',
      name: 'product-list',
      component: () => import('./views/pages/app/products/product-list.vue'),
    },
  ],
};
