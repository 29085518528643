// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateSequenceNumberRequestModel from '../../model/update-sequence-number-request-model';
import UpdateSequenceNumberRequestDTO from '../dto/update-sequence-number-request-dto';

export default abstract class UpdateSequenceNumberRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateSequenceNumberRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.nextNumber = data.nextNumber;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  * @type {int32}
  */
  'nextNumber'?: number;

  static toModel(dto: DeepPartial<UpdateSequenceNumberRequestDTO>): UpdateSequenceNumberRequestModel;
  static toModel(dto: DeepPartial<UpdateSequenceNumberRequestDTO> | undefined | null): UpdateSequenceNumberRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateSequenceNumberRequestDTO> | undefined | null): UpdateSequenceNumberRequestModel | undefined {
    return dto ? new UpdateSequenceNumberRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateSequenceNumberRequestModel> | UpdateSequenceNumberRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'nextNumber': unwrapped.nextNumber,
    } as UpdateSequenceNumberRequestDTO;
  }
}
