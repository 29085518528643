// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AppLogModel from '../../model/app-log-model';
import AppLogDTO from '../dto/app-log-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class AppLogModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AppLogDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.message = data.message ?? undefined;
      this.level = data.level ?? undefined;
      this.exception = data.exception ?? undefined;
      this.timeStamp = data.timeStamp;
      this.correlationId = data.correlationId ?? undefined;
      this.appTransactionId = data.appTransactionId ?? undefined;
      this.appUserId = data.appUserId ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  */
  'message'?: string;
  /**
  */
  'level'?: string;
  /**
  */
  'exception'?: string;
  /**
  * @type {date-time}
  */
  'timeStamp'?: string;
  /**
  */
  'correlationId'?: string;
  /**
  */
  'appTransactionId'?: string;
  /**
  * @type {int64}
  */
  'appUserId'?: number;

  static toModel(dto: DeepPartial<AppLogDTO>): AppLogModel;
  static toModel(dto: DeepPartial<AppLogDTO> | undefined | null): AppLogModel | undefined;
  static toModel(dto: DeepPartial<AppLogDTO> | undefined | null): AppLogModel | undefined {
    return dto ? new AppLogModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AppLogModel> | AppLogModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'message': unwrapped.message,
      'level': unwrapped.level,
      'exception': unwrapped.exception,
      'timeStamp': unwrapped.timeStamp,
      'correlationId': unwrapped.correlationId,
      'appTransactionId': unwrapped.appTransactionId,
      'appUserId': unwrapped.appUserId,
    } as AppLogDTO;
  }
}
