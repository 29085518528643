import { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import EmployeeModel from '../../model/employee-model';
import employeesServices from '../../_generated/api/employees';
import GetEmployeeByUserIdRequestModel from '../../model/get-employee-by-user-id-request-model';
import ImportEmployeesRequestModel from '../../model/import-employees-request-model';

export default (service: Servicelayer, log: Logger) => {
  const baseService = employeesServices(service);
  return {

    async getEmployeeByUserId(isOnline: boolean, request: GetEmployeeByUserIdRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<EmployeeModel>> {
      const ret = await baseService.getEmployeeByUserId(request, config);
      return ret;
    },
    async importEmployees(request: ImportEmployeesRequestModel, config?: RequestConfig): Promise<void> {
      const ret = await baseService.importEmployees(request, config);
      return ret;
    },
  };
};
