




















































































import Vue from 'vue';
import DataDownloadViewModel from './data-download.vue.model';

export default Vue.extend({
  auth: true,
  data: () => ({
    vm: new DataDownloadViewModel(),
  }),
  mounted() {
    this.vm.initialize();
  },
  methods: {
    onFilterChange() {
      this.vm.applyFilter();
    },
  },
});
