import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import productsCategoriesServices from '../../_generated/api/product-categories';
import GetProductCategoriesRequestModel from '../../model/get-product-categories-request-model';
import ProductCategoryListResultModel from '../../model/product-category-list-result-model';

export default (service: Servicelayer, log: Logger) => {
  const baseService = productsCategoriesServices(service);
  return {

    async getProductCategories(isOnline: boolean, request: GetProductCategoriesRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<ProductCategoryListResultModel>> {
      const ret = await baseService.getProductCategories(request, config);
      return ret;
    },
  };
};
