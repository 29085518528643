// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ContactIdMappingListModel from '../../model/contact-id-mapping-list-model';
import ContactIdMappingListDTO from '../dto/contact-id-mapping-list-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ContactIdMappingListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ContactIdMappingListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.crmId = data.crmId;
      this.foreignAppSystemId = data.foreignAppSystemId;
      this.foreignAppSystemKey = data.foreignAppSystemKey ?? undefined;
      this.foreignId = data.foreignId ?? undefined;
      this.crmStateId = data.crmStateId;
      this.crmStateName = data.crmStateName ?? undefined;
      this.mappingStateId = data.mappingStateId ?? undefined;
      this.mappingStateName = data.mappingStateName ?? undefined;
      this.mappingStateReason = data.mappingStateReason ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {uuid}
  */
  'crmId'?: string;
  /**
  * @type {int64}
  */
  'foreignAppSystemId'?: number;
  /**
  */
  'foreignAppSystemKey'?: string;
  /**
  */
  'foreignId'?: string;
  /**
  * @type {int32}
  */
  'crmStateId'?: number;
  /**
  */
  'crmStateName'?: string;
  /**
  * @type {int32}
  */
  'mappingStateId'?: number;
  /**
  */
  'mappingStateName'?: string;
  /**
  */
  'mappingStateReason'?: string;

  static toModel(dto: DeepPartial<ContactIdMappingListDTO>): ContactIdMappingListModel;
  static toModel(dto: DeepPartial<ContactIdMappingListDTO> | undefined | null): ContactIdMappingListModel | undefined;
  static toModel(dto: DeepPartial<ContactIdMappingListDTO> | undefined | null): ContactIdMappingListModel | undefined {
    return dto ? new ContactIdMappingListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ContactIdMappingListModel> | ContactIdMappingListModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'crmId': unwrapped.crmId,
      'foreignAppSystemId': unwrapped.foreignAppSystemId,
      'foreignAppSystemKey': unwrapped.foreignAppSystemKey,
      'foreignId': unwrapped.foreignId,
      'crmStateId': unwrapped.crmStateId,
      'crmStateName': unwrapped.crmStateName,
      'mappingStateId': unwrapped.mappingStateId,
      'mappingStateName': unwrapped.mappingStateName,
      'mappingStateReason': unwrapped.mappingStateReason,
    } as ContactIdMappingListDTO;
  }
}
