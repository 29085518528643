import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';

export default class OfflineCachingViewModel extends PageBase {
  progress = 0;
  lastDownloadDate: string | undefined;
  currentStep = 0;
  totalSteps = 0;
  async initialize(): Promise<void> {
    try {
      await Vue.$service.v2.api.offline.preloadResourcesForOffline(this, this.updateProgress);
    } catch (error: any) {
      Vue.$alert.error(error);
    }
  }

  downloadFinished(): boolean {
    return this.progress === 100;
  }

  updateProgress(scope: OfflineCachingViewModel, percent: number, currentStep: number, totalSteps: number) {
    scope.progress = percent * 100;
    scope.currentStep = currentStep;
    scope.totalSteps = totalSteps;
  }
}
