











































































































import Vue from 'vue';
import CustomerOrderListViewModel from './customer-order-list.vue.model';

export default Vue.extend({
  auth: true,
  data: () => ({
    vm: new CustomerOrderListViewModel(),
  }),
  mounted() {
    this.vm.initialize();
  },
  methods: {
    openSendDisableInfo(errors:string[]) {
      this.$dialog.notify(Vue.$t('page.customerOrderList.label.errorSendingorder.title'), errors.join('\n'));
    },
  },
});
