






















import Vue, { PropType } from 'vue';
import OrderItemArticleModel from '../../services/v2/model/order-item-article-model';

export default Vue.extend({
  components: {
    bvProductListEntry: () => import(/* webpackPrefetch: true */ './bv-product-list-entry.vue'),
    IlLazy: () => import(/* webpackPrefetch: true */ '../il-lazy.vue'),
  },
  props: {
    items: { type: Array as PropType<Array<OrderItemArticleModel>>, default: undefined },
    productColors: { type: Array as PropType<Array<string>>, default: () => [] },
    isReadOnly: { type: Boolean, default: true },
    isShowQuantity: { type: Boolean, default: false },
  },
});
