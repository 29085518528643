// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import Int32LookupRequestModel from '../../model/int32-lookup-request-model';
import Int32LookupRequestDTO from '../dto/int32-lookup-request-dto';
import ApiPagingRequestModel from '../../model/api-paging-request-model';

export default abstract class Int32LookupRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<Int32LookupRequestDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.key = data.key ?? undefined;
      this.searchCriteria = data.searchCriteria ?? undefined;
      this.paging = data.paging ? ApiPagingRequestModel.toModel(data.paging) : undefined;
    }
  }
  /**
  * @type {int32}
  */
  'id'?: number;
  /**
  */
  'key'?: string;
  /**
  */
  'searchCriteria'?: string;
  /**
  */
  'paging'?: ApiPagingRequestModel;

  static toModel(dto: DeepPartial<Int32LookupRequestDTO>): Int32LookupRequestModel;
  static toModel(dto: DeepPartial<Int32LookupRequestDTO> | undefined | null): Int32LookupRequestModel | undefined;
  static toModel(dto: DeepPartial<Int32LookupRequestDTO> | undefined | null): Int32LookupRequestModel | undefined {
    return dto ? new Int32LookupRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<Int32LookupRequestModel> | Int32LookupRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'key': unwrapped.key,
      'searchCriteria': unwrapped.searchCriteria,
      'paging': unwrapped.paging,
    } as Int32LookupRequestDTO;
  }
}
