// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ResendVerificationCodeRequestModel from '../../model/resend-verification-code-request-model';
import ResendVerificationCodeRequestDTO from '../dto/resend-verification-code-request-dto';

export default abstract class ResendVerificationCodeRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ResendVerificationCodeRequestDTO>) {
    super();
    if (data) {
      this.email = data.email;
    }
  }
  /**
  * @type {email}
  */
  'email'?: string;

  static toModel(dto: DeepPartial<ResendVerificationCodeRequestDTO>): ResendVerificationCodeRequestModel;
  static toModel(dto: DeepPartial<ResendVerificationCodeRequestDTO> | undefined | null): ResendVerificationCodeRequestModel | undefined;
  static toModel(dto: DeepPartial<ResendVerificationCodeRequestDTO> | undefined | null): ResendVerificationCodeRequestModel | undefined {
    return dto ? new ResendVerificationCodeRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ResendVerificationCodeRequestModel> | ResendVerificationCodeRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'email': unwrapped.email,
    } as ResendVerificationCodeRequestDTO;
  }
}
