// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCustomersRequestModel from '../../model/get-customers-request-model';
import GetAllCustomersRequestModel from '../../model/get-all-customers-request-model';
import LookupCustomersRequestModel from '../../model/lookup-customers-request-model';
import GetCustomerRequestModel from '../../model/get-customer-request-model';
import ImportCustomersRequestModel from '../../model/import-customers-request-model';
import CustomerPagingResultModel from '../../model/customer-paging-result-model';
import CustomerListResultModel from '../../model/customer-list-result-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';
import CustomerModel from '../../model/customer-model';

export default (service: Servicelayer) => ({
  /**  */
  async getCustomers(request: GetCustomersRequestModel, config?: RequestConfig) {
    const requestDTO = GetCustomersRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-customers'] ?? 'Customers';
    const response = await service.get<any>(endpointPath, {
      query: {
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
        employeeId: requestDTO.employeeId,
        searchCriteria: requestDTO.searchCriteria,
        customerNumber: requestDTO.customerNumber,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CustomerPagingResultModel.toModel(dto));
  },
  /**  */
  async getAllCustomers(request: GetAllCustomersRequestModel, config?: RequestConfig) {
    const requestDTO = GetAllCustomersRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-all-customers'] ?? 'Customers/All';
    const response = await service.get<any>(endpointPath, {
      query: {
        employeeId: requestDTO.employeeId,
        searchCriteria: requestDTO.searchCriteria,
        customerNumber: requestDTO.customerNumber,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CustomerListResultModel.toModel(dto));
  },
  /**  */
  async lookupCustomers(request: LookupCustomersRequestModel, config?: RequestConfig) {
    const requestDTO = LookupCustomersRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-customers'] ?? 'Customers/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        employeeId: requestDTO.employeeId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
  /**  */
  async getCustomer(request: GetCustomerRequestModel, config?: RequestConfig) {
    const requestDTO = GetCustomerRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-customer'] ?? 'Customers/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CustomerModel.toModel(dto));
  },
  /**  */
  async importCustomers(request: ImportCustomersRequestModel, config?: RequestConfig) {
    const requestDTO = ImportCustomersRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('formFile', requestDTO.formFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-import-customers'] ?? 'Customers/Import';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
  },
});
