// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetIntegrationCallOverviewRequestModel from '../../model/get-integration-call-overview-request-model';
import GetIntegrationCallOverviewRequestDTO from '../dto/get-integration-call-overview-request-dto';

export default abstract class GetIntegrationCallOverviewRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetIntegrationCallOverviewRequestDTO>) {
    super();
    if (data) {
      this.from = data.from;
      this.to = data.to;
    }
  }
  /**
  * @type {date-time}
  */
  'from'?: string;
  /**
  * @type {date-time}
  */
  'to'?: string;

  static toModel(dto: DeepPartial<GetIntegrationCallOverviewRequestDTO>): GetIntegrationCallOverviewRequestModel;
  static toModel(dto: DeepPartial<GetIntegrationCallOverviewRequestDTO> | undefined | null): GetIntegrationCallOverviewRequestModel | undefined;
  static toModel(dto: DeepPartial<GetIntegrationCallOverviewRequestDTO> | undefined | null): GetIntegrationCallOverviewRequestModel | undefined {
    return dto ? new GetIntegrationCallOverviewRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetIntegrationCallOverviewRequestModel> | GetIntegrationCallOverviewRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'from': unwrapped.from,
      'to': unwrapped.to,
    } as GetIntegrationCallOverviewRequestDTO;
  }
}
