// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import IntegrationCallStepPagingResultModel from '../../model/integration-call-step-paging-result-model';
import IntegrationCallStepPagingResultDTO from '../dto/integration-call-step-paging-result-dto';
import IntegrationCallStepModel from '../../model/integration-call-step-model';
import ResourceLinkModel from '../../model/resource-link-model';
import PaginationDataModel from '../../model/pagination-data-model';

export default abstract class IntegrationCallStepPagingResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<IntegrationCallStepPagingResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => IntegrationCallStepModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.paginationData = data.paginationData ? PaginationDataModel.toModel(data.paginationData) : undefined;
    }
  }
  /**
  */
  'items'?: IntegrationCallStepModel[];
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  */
  'paginationData'?: PaginationDataModel;

  static toModel(dto: DeepPartial<IntegrationCallStepPagingResultDTO>): IntegrationCallStepPagingResultModel;
  static toModel(dto: DeepPartial<IntegrationCallStepPagingResultDTO> | undefined | null): IntegrationCallStepPagingResultModel | undefined;
  static toModel(dto: DeepPartial<IntegrationCallStepPagingResultDTO> | undefined | null): IntegrationCallStepPagingResultModel | undefined {
    return dto ? new IntegrationCallStepPagingResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<IntegrationCallStepPagingResultModel> | IntegrationCallStepPagingResultModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'items': unwrapped.items,
      'links': unwrapped.links,
      'paginationData': unwrapped.paginationData,
    } as IntegrationCallStepPagingResultDTO;
  }
}
