// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CompleteIntegrationCallRequestModel from '../../model/complete-integration-call-request-model';
import CompleteIntegrationCallRequestDTO from '../dto/complete-integration-call-request-dto';

export default abstract class CompleteIntegrationCallRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CompleteIntegrationCallRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId;
      this.comment = data.comment;
      this.id = data.id;
    }
  }
  /**
  * @type {int64}
  */
  'userId'?: number;
  /**
  */
  'comment'?: string;
  /**
  * @type {int64}
  */
  'id'?: number;

  static toModel(dto: DeepPartial<CompleteIntegrationCallRequestDTO>): CompleteIntegrationCallRequestModel;
  static toModel(dto: DeepPartial<CompleteIntegrationCallRequestDTO> | undefined | null): CompleteIntegrationCallRequestModel | undefined;
  static toModel(dto: DeepPartial<CompleteIntegrationCallRequestDTO> | undefined | null): CompleteIntegrationCallRequestModel | undefined {
    return dto ? new CompleteIntegrationCallRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CompleteIntegrationCallRequestModel> | CompleteIntegrationCallRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'userId': unwrapped.userId,
      'comment': unwrapped.comment,
      'id': unwrapped.id,
    } as CompleteIntegrationCallRequestDTO;
  }
}
