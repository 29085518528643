// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import RegisterRequestModel from '../../model/register-request-model';
import RegisterRequestDTO from '../dto/register-request-dto';

export default abstract class RegisterRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<RegisterRequestDTO>) {
    super();
    if (data) {
      this.email = data.email;
      this.userName = data.userName;
      this.password = data.password;
      this.userLanguage = data.userLanguage ?? undefined;
      this.userCulture = data.userCulture ?? undefined;
      this.isGdprConsentAccepted = data.isGdprConsentAccepted;
      this.registrationKey = data.registrationKey ?? undefined;
    }
  }
  /**
  * @type {email}
  */
  'email'?: string;
  /**
  */
  'userName'?: string;
  /**
  */
  'password'?: string;
  /**
  */
  'userLanguage'?: string;
  /**
  */
  'userCulture'?: string;
  /**
  */
  'isGdprConsentAccepted'?: boolean;
  /**
  */
  'registrationKey'?: string;

  static toModel(dto: DeepPartial<RegisterRequestDTO>): RegisterRequestModel;
  static toModel(dto: DeepPartial<RegisterRequestDTO> | undefined | null): RegisterRequestModel | undefined;
  static toModel(dto: DeepPartial<RegisterRequestDTO> | undefined | null): RegisterRequestModel | undefined {
    return dto ? new RegisterRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<RegisterRequestModel> | RegisterRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'email': unwrapped.email,
      'userName': unwrapped.userName,
      'password': unwrapped.password,
      'userLanguage': unwrapped.userLanguage,
      'userCulture': unwrapped.userCulture,
      'isGdprConsentAccepted': unwrapped.isGdprConsentAccepted,
      'registrationKey': unwrapped.registrationKey,
    } as RegisterRequestDTO;
  }
}
