// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SetUserAppRoleRequestModel from '../../model/set-user-app-role-request-model';
import SetUserAppRoleRequestDTO from '../dto/set-user-app-role-request-dto';

export default abstract class SetUserAppRoleRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SetUserAppRoleRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.newRoleId = data.newRoleId;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  * @type {int32}
  */
  'newRoleId'?: number;

  static toModel(dto: DeepPartial<SetUserAppRoleRequestDTO>): SetUserAppRoleRequestModel;
  static toModel(dto: DeepPartial<SetUserAppRoleRequestDTO> | undefined | null): SetUserAppRoleRequestModel | undefined;
  static toModel(dto: DeepPartial<SetUserAppRoleRequestDTO> | undefined | null): SetUserAppRoleRequestModel | undefined {
    return dto ? new SetUserAppRoleRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SetUserAppRoleRequestModel> | SetUserAppRoleRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'newRoleId': unwrapped.newRoleId,
    } as SetUserAppRoleRequestDTO;
  }
}
