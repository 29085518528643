import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import GetTokenRequestModel from '../../model/get-token-request-model';
import RefreshTokenRequestModel from '../../model/refresh-token-request-model';
import authServices from '../../_generated/api/auth';

export default (service: Servicelayer, log: Logger) => {
  const baseService = authServices(service);

  return {
    async getToken(isOnline: boolean, request: GetTokenRequestModel, config?: RequestConfig) {
      const ret = baseService.getToken(request, config);
      return ret;
    },
    /**  */
    async refreshToken(isOnline: boolean, request: RefreshTokenRequestModel, config?: RequestConfig) {
      const ret = baseService.refreshToken(request, config);
      return ret;
    },
  };
};
