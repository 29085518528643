// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormContactModel from '../../model/dynamic-form-contact-model';
import DynamicFormContactDTO from '../dto/dynamic-form-contact-dto';

export default abstract class DynamicFormContactModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormContactDTO>) {
    super();
    if (data) {
      this.salutation = data.salutation;
      this.companyName = data.companyName ?? undefined;
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.street = data.street ?? undefined;
      this.houseNumber = data.houseNumber ?? undefined;
      this.addressLine1 = data.addressLine1 ?? undefined;
      this.poBox = data.poBox ?? undefined;
      this.countryIso2 = data.countryIso2 ?? undefined;
      this.postalCode = data.postalCode ?? undefined;
      this.city = data.city ?? undefined;
      this.phoneNumber = data.phoneNumber ?? undefined;
      this.mobilePhoneNumber = data.mobilePhoneNumber ?? undefined;
      this.eMail = data.eMail ?? undefined;
      this.dateOfBirth = data.dateOfBirth ?? undefined;
    }
  }
  /**
  * @type {int32}
  */
  'salutation'?: 0 | 1 | 2 | 3; // DynamicFormContactSalutationDTO
  /**
  */
  'companyName'?: string;
  /**
  */
  'firstName'?: string;
  /**
  */
  'lastName'?: string;
  /**
  */
  'street'?: string;
  /**
  */
  'houseNumber'?: string;
  /**
  */
  'addressLine1'?: string;
  /**
  */
  'poBox'?: string;
  /**
  */
  'countryIso2'?: string;
  /**
  */
  'postalCode'?: string;
  /**
  */
  'city'?: string;
  /**
  */
  'phoneNumber'?: string;
  /**
  */
  'mobilePhoneNumber'?: string;
  /**
  */
  'eMail'?: string;
  /**
  * @type {date-time}
  */
  'dateOfBirth'?: string;

  static toModel(dto: DeepPartial<DynamicFormContactDTO>): DynamicFormContactModel;
  static toModel(dto: DeepPartial<DynamicFormContactDTO> | undefined | null): DynamicFormContactModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormContactDTO> | undefined | null): DynamicFormContactModel | undefined {
    return dto ? new DynamicFormContactModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormContactModel> | DynamicFormContactModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'salutation': unwrapped.salutation,
      'companyName': unwrapped.companyName,
      'firstName': unwrapped.firstName,
      'lastName': unwrapped.lastName,
      'street': unwrapped.street,
      'houseNumber': unwrapped.houseNumber,
      'addressLine1': unwrapped.addressLine1,
      'poBox': unwrapped.poBox,
      'countryIso2': unwrapped.countryIso2,
      'postalCode': unwrapped.postalCode,
      'city': unwrapped.city,
      'phoneNumber': unwrapped.phoneNumber,
      'mobilePhoneNumber': unwrapped.mobilePhoneNumber,
      'eMail': unwrapped.eMail,
      'dateOfBirth': unwrapped.dateOfBirth,
    } as DynamicFormContactDTO;
  }
}
