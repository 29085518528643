// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SequenceNumberListModel from '../../model/sequence-number-list-model';
import SequenceNumberListDTO from '../dto/sequence-number-list-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class SequenceNumberListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SequenceNumberListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.name = data.name ?? undefined;
      this.sequenceName = data.sequenceName ?? undefined;
      this.startNumber = data.startNumber;
      this.increment = data.increment;
      this.currentValue = data.currentValue;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  */
  'creatorName'?: string;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  */
  'modifierName'?: string;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  */
  'name'?: string;
  /**
  */
  'sequenceName'?: string;
  /**
  * @type {int32}
  */
  'startNumber'?: number;
  /**
  * @type {int32}
  */
  'increment'?: number;
  /**
  * @type {int32}
  */
  'currentValue'?: number;

  static toModel(dto: DeepPartial<SequenceNumberListDTO>): SequenceNumberListModel;
  static toModel(dto: DeepPartial<SequenceNumberListDTO> | undefined | null): SequenceNumberListModel | undefined;
  static toModel(dto: DeepPartial<SequenceNumberListDTO> | undefined | null): SequenceNumberListModel | undefined {
    return dto ? new SequenceNumberListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SequenceNumberListModel> | SequenceNumberListModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'creatorId': unwrapped.creatorId,
      'creatorName': unwrapped.creatorName,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modifierName': unwrapped.modifierName,
      'modified': unwrapped.modified,
      'name': unwrapped.name,
      'sequenceName': unwrapped.sequenceName,
      'startNumber': unwrapped.startNumber,
      'increment': unwrapped.increment,
      'currentValue': unwrapped.currentValue,
    } as SequenceNumberListDTO;
  }
}
