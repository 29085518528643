import Vue from 'vue';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';

export default class ProductImageViewModel extends ViewModelBase {
  request = new AbortController();
  signal: AbortSignal | undefined;
  key: number | undefined;
  private imgBlob: Blob | undefined = undefined;
  // eslint-disable-next-line no-empty-function
  async initialize(): Promise<void> {
    this.signal = this.request.signal;
  }
  async getProduct(productId: number | undefined): Promise<Blob | undefined> {
    if (productId && productId !== this.key) {
      this.key = productId;
      // No try catch since this is handled by the p-img api-src
      this.imgBlob = await Vue.$service.v2.api.productImages.getProductImageForceOnline(Vue.$pwa.isOnline, productId, { signal: this.signal });
    }
    return Promise.resolve(this.imgBlob);
  }
  destroy() {
    this.imgBlob = undefined;
    this.request.abort();
  }
}
