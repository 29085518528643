// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ApiPagingRequestModel from '../../model/api-paging-request-model';
import ApiPagingRequestDTO from '../dto/api-paging-request-dto';

export default abstract class ApiPagingRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ApiPagingRequestDTO>) {
    super();
    if (data) {
      this.currentPage = data.currentPage;
      this.pageSize = data.pageSize;
      this.includeCount = data.includeCount;
      this.noPaging = data.noPaging;
    }
  }
  /**
  * @type {int32}
  */
  'currentPage'?: number;
  /**
  * @type {int32}
  */
  'pageSize'?: number;
  /**
  */
  'includeCount'?: boolean;
  /**
  */
  'noPaging'?: boolean;

  static toModel(dto: DeepPartial<ApiPagingRequestDTO>): ApiPagingRequestModel;
  static toModel(dto: DeepPartial<ApiPagingRequestDTO> | undefined | null): ApiPagingRequestModel | undefined;
  static toModel(dto: DeepPartial<ApiPagingRequestDTO> | undefined | null): ApiPagingRequestModel | undefined {
    return dto ? new ApiPagingRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ApiPagingRequestModel> | ApiPagingRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'currentPage': unwrapped.currentPage,
      'pageSize': unwrapped.pageSize,
      'includeCount': unwrapped.includeCount,
      'noPaging': unwrapped.noPaging,
    } as ApiPagingRequestDTO;
  }
}
