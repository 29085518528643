import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import ImportCustomersRequestModel from '../../../../services/v2/model/import-customers-request-model';
import ImportEmployeesRequestModel from '../../../../services/v2/model/import-employees-request-model';
import ImportProductThumbnailRequestModel from '../../../../services/v2/model/import-product-thumbnail-request-model';
import ImportProductsRequestModel from '../../../../services/v2/model/import-products-request-model';

export default class DataUploadViewModel extends PageBase {
  isEmployeesLoading = false;
  isCustomersLoading = false;
  isArticlesLoading = false;
  isImagesLoading = false;
  isRequestSuccess = false;
  productId: number | undefined;

  initialize(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  canUploadImages() {
    return this.productId && Vue.$pwa.isOnline;
  }

  async onEmployeeFileSelected(scope: DataUploadViewModel, file: File) {
    scope.isRequestSuccess = false;
    scope.isEmployeesLoading = true;
    const req = new ImportEmployeesRequestModel();
    req.formFile = file;
    try {
      const response = await Vue.$service.v2.api.employees.importEmployees(req);
      scope.isRequestSuccess = true;
      Vue.$log.info(response);
    } catch (error: any) {
      Vue.$alert.error(error);
    } finally {
      scope.isEmployeesLoading = false;
    }
  }
  async onCustomerFileSelected(scope: DataUploadViewModel, file: File) {
    scope.isRequestSuccess = false;
    const req = new ImportCustomersRequestModel();
    req.formFile = file;
    try {
      scope.isCustomersLoading = true;
      const response = await Vue.$service.v2.api.customers.importCustomers(Vue.$pwa.isOnline, req);
      scope.isRequestSuccess = true;
      Vue.$log.info(response);
    } catch (error: any) {
      Vue.$alert.error(error);
    } finally {
      scope.isCustomersLoading = false;
    }
  }
  async onArticlesFileSelected(scope: DataUploadViewModel, file: File) {
    scope.isRequestSuccess = false;
    const req = new ImportProductsRequestModel();
    req.formFile = file;
    try {
      scope.isArticlesLoading = true;
      const response = await Vue.$service.v2.api.products.importProducts(Vue.$pwa.isOnline, req);
      scope.isRequestSuccess = true;
      Vue.$log.info(response);
    } catch (error: any) {
      Vue.$alert.error(error);
    } finally {
      scope.isArticlesLoading = false;
    }
  }
  async onProductImageSelected(scope: DataUploadViewModel, productId: number | undefined, file: File) {
    if (!productId) {
      Vue.$log.error('No product selected!');
    }
    scope.isRequestSuccess = false;
    scope.isImagesLoading = true;
    const req = new ImportProductThumbnailRequestModel();
    req.formFile = file;
    req.id = productId!;
    try {
      const response = await Vue.$service.v2.api.products.importProductThumbnail(Vue.$pwa.isOnline, req);
      scope.isRequestSuccess = true;
      Vue.$log.info(response);
    } catch (error: any) {
      Vue.$alert.error(error);
    } finally {
      scope.isImagesLoading = false;
    }
  }
}
