// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetProductsRequestModel from '../../model/get-products-request-model';
import GetProductsRequestDTO from '../dto/get-products-request-dto';

export default abstract class GetProductsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetProductsRequestDTO>) {
    super();
    if (data) {
      this.searchCriteria = data.searchCriteria;
      this.articleNumber = data.articleNumber;
    }
  }
  /**
  */
  'searchCriteria'?: string;
  /**
  */
  'articleNumber'?: string;

  static toModel(dto: DeepPartial<GetProductsRequestDTO>): GetProductsRequestModel;
  static toModel(dto: DeepPartial<GetProductsRequestDTO> | undefined | null): GetProductsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetProductsRequestDTO> | undefined | null): GetProductsRequestModel | undefined {
    return dto ? new GetProductsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetProductsRequestModel> | GetProductsRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'searchCriteria': unwrapped.searchCriteria,
      'articleNumber': unwrapped.articleNumber,
    } as GetProductsRequestDTO;
  }
}
