
















import Vue from 'vue';
import ProductImageViewModel from './bv-product-image.vue.model';

export default Vue.extend({
  props: {
    productId: { type: Number, default: undefined },
  },
  data: () => ({
    vm: new ProductImageViewModel(),
  }),
  mounted() {
    this.vm.initialize();
  },
  beforeDestroy() {
    this.vm.destroy();
  },
});
