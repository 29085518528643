// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import Int32LookupRequestListResultModel from '../../model/int32-lookup-request-list-result-model';
import Int32LookupRequestListResultDTO from '../dto/int32-lookup-request-list-result-dto';
import Int32LookupRequestModel from '../../model/int32-lookup-request-model';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class Int32LookupRequestListResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<Int32LookupRequestListResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => Int32LookupRequestModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  'items'?: Int32LookupRequestModel[];
  /**
  */
  'links'?: ResourceLinkModel[];

  static toModel(dto: DeepPartial<Int32LookupRequestListResultDTO>): Int32LookupRequestListResultModel;
  static toModel(dto: DeepPartial<Int32LookupRequestListResultDTO> | undefined | null): Int32LookupRequestListResultModel | undefined;
  static toModel(dto: DeepPartial<Int32LookupRequestListResultDTO> | undefined | null): Int32LookupRequestListResultModel | undefined {
    return dto ? new Int32LookupRequestListResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<Int32LookupRequestListResultModel> | Int32LookupRequestListResultModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'items': unwrapped.items,
      'links': unwrapped.links,
    } as Int32LookupRequestListResultDTO;
  }
}
