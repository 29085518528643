// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetAppLogsRequestModel from '../../model/get-app-logs-request-model';
import GetAppLogsRequestDTO from '../dto/get-app-logs-request-dto';

export default abstract class GetAppLogsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetAppLogsRequestDTO>) {
    super();
    if (data) {
      this.dateFrom = data.dateFrom;
      this.dateTo = data.dateTo;
      this.levels = data.levels;
      this.correlationId = data.correlationId;
      this.appTransactionId = data.appTransactionId;
      this.appUserId = data.appUserId;
      this.searchCriteria = data.searchCriteria;
      this['paging.CurrentPage'] = data['paging.CurrentPage'];
      this['paging.PageSize'] = data['paging.PageSize'];
      this['paging.IncludeCount'] = data['paging.IncludeCount'];
      this['paging.NoPaging'] = data['paging.NoPaging'];
    }
  }
  /**
  * @type {date-time}
  */
  'dateFrom'?: string;
  /**
  * @type {date-time}
  */
  'dateTo'?: string;
  /**
  */
  'levels'?: string[];
  /**
  */
  'correlationId'?: string;
  /**
  */
  'appTransactionId'?: string;
  /**
  * @type {int64}
  */
  'appUserId'?: number;
  /**
  */
  'searchCriteria'?: string;
  /**
  * @type {int32}
  */
  'paging.CurrentPage'?: number;
  /**
  * @type {int32}
  */
  'paging.PageSize'?: number;
  /**
  */
  'paging.IncludeCount'?: boolean;
  /**
  */
  'paging.NoPaging'?: boolean;

  static toModel(dto: DeepPartial<GetAppLogsRequestDTO>): GetAppLogsRequestModel;
  static toModel(dto: DeepPartial<GetAppLogsRequestDTO> | undefined | null): GetAppLogsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetAppLogsRequestDTO> | undefined | null): GetAppLogsRequestModel | undefined {
    return dto ? new GetAppLogsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetAppLogsRequestModel> | GetAppLogsRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'dateFrom': unwrapped.dateFrom,
      'dateTo': unwrapped.dateTo,
      'levels': unwrapped.levels,
      'correlationId': unwrapped.correlationId,
      'appTransactionId': unwrapped.appTransactionId,
      'appUserId': unwrapped.appUserId,
      'searchCriteria': unwrapped.searchCriteria,
      'paging.CurrentPage': unwrapped['paging.CurrentPage'],
      'paging.PageSize': unwrapped['paging.PageSize'],
      'paging.IncludeCount': unwrapped['paging.IncludeCount'],
      'paging.NoPaging': unwrapped['paging.NoPaging'],
    } as GetAppLogsRequestDTO;
  }
}
