import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ProductDTO from '../_generated/dto/product-dto';
import ProductModel from './product-model';

export default class OrderItemArticleModel extends ProductModel {
  constructor(data?: DeepPartial<OrderItemArticleModel>) {
    super({ ...data } as DeepPartial<ProductDTO>);
    this.productId = data?.productId;
    this.quantity = data?.quantity || 0;
  }
  productId: number | undefined;
  quantity: number = 0;
  selectedTitles: string[] | undefined;
}
