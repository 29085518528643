import Vue from 'vue';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import Int64LookupPagingResultModel from '../../services/v2/model/int64-lookup-list-result-model';
import Int64LookupModel from '../../services/v2/model/int64-lookup-model';
import LookupProductsRequestModel from '../../services/v2/model/lookup-products-request-model';

export default class ProductLookupViewModel extends ViewModelBase {
  filter: {
    searchCriteria?: string
  } = {};
  productsLookupDataSource = new DataSource({
    selectCommand: (request: LookupProductsRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<Int64LookupPagingResultModel>> => Vue.$service.v2.api.products.lookupProducts(Vue.$pwa.isOnline, request, config),
  });

  get items(): Int64LookupModel[] {
    return this.productsLookupDataSource.data?.items?.map((i) => i.data as Int64LookupModel) ?? [];
  }

  async initialize(): Promise<void> {
    await this.productsLookupDataSource.initialize();
  }
}
