// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormDefinitionModel from '../../model/dynamic-form-definition-model';
import DynamicFormDefinitionDTO from '../dto/dynamic-form-definition-dto';
import DynamicFormFieldDefinitionModel from '../../model/dynamic-form-field-definition-model';

export default abstract class DynamicFormDefinitionModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormDefinitionDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.caption = data.caption ?? undefined;
      this.fields = data.fields?.map((i) => DynamicFormFieldDefinitionModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  'id'?: string;
  /**
  */
  'caption'?: string;
  /**
  */
  'fields'?: DynamicFormFieldDefinitionModel[];

  static toModel(dto: DeepPartial<DynamicFormDefinitionDTO>): DynamicFormDefinitionModel;
  static toModel(dto: DeepPartial<DynamicFormDefinitionDTO> | undefined | null): DynamicFormDefinitionModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormDefinitionDTO> | undefined | null): DynamicFormDefinitionModel | undefined {
    return dto ? new DynamicFormDefinitionModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormDefinitionModel> | DynamicFormDefinitionModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'caption': unwrapped.caption,
      'fields': unwrapped.fields,
    } as DynamicFormDefinitionDTO;
  }
}
