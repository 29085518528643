// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormFieldDefinitionModel from '../../model/dynamic-form-field-definition-model';
import DynamicFormFieldDefinitionDTO from '../dto/dynamic-form-field-definition-dto';
import DynamicFormPicklistItemDefinitionModel from '../../model/dynamic-form-picklist-item-definition-model';

export default abstract class DynamicFormFieldDefinitionModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormFieldDefinitionDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.caption = data.caption ?? undefined;
      this.order = data.order;
      this.inputType = data.inputType;
      this.isRequired = data.isRequired;
      this.isMultiInput = data.isMultiInput;
      this.isClearable = data.isClearable;
      this.picklistItems = data.picklistItems?.map((i) => DynamicFormPicklistItemDefinitionModel.toModel(i)) ?? [];
      this.min = data.min ?? undefined;
      this.max = data.max ?? undefined;
      this.defaultValue = data.defaultValue ?? undefined;
      this.placeholder = data.placeholder ?? undefined;
      this.description = data.description ?? undefined;
      this.decimalPlaces = data.decimalPlaces ?? undefined;
      this.items = data.items?.map((i) => DynamicFormFieldDefinitionModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  'id'?: string;
  /**
  */
  'caption'?: string;
  /**
  * @type {int32}
  */
  'order'?: number;
  /**
  * @type {int32}
  */
  'inputType'?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16; // DynamicFormFieldInputTypeDTO
  /**
  */
  'isRequired'?: boolean;
  /**
  */
  'isMultiInput'?: boolean;
  /**
  */
  'isClearable'?: boolean;
  /**
  */
  'picklistItems'?: DynamicFormPicklistItemDefinitionModel[];
  /**
  */
  'min'?: string;
  /**
  */
  'max'?: string;
  /**
  */
  'defaultValue'?: string;
  /**
  */
  'placeholder'?: string;
  /**
  */
  'description'?: string;
  /**
  * @type {int32}
  */
  'decimalPlaces'?: number;
  /**
  */
  'items'?: DynamicFormFieldDefinitionModel[];

  static toModel(dto: DeepPartial<DynamicFormFieldDefinitionDTO>): DynamicFormFieldDefinitionModel;
  static toModel(dto: DeepPartial<DynamicFormFieldDefinitionDTO> | undefined | null): DynamicFormFieldDefinitionModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormFieldDefinitionDTO> | undefined | null): DynamicFormFieldDefinitionModel | undefined {
    return dto ? new DynamicFormFieldDefinitionModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormFieldDefinitionModel> | DynamicFormFieldDefinitionModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'caption': unwrapped.caption,
      'order': unwrapped.order,
      'inputType': unwrapped.inputType,
      'isRequired': unwrapped.isRequired,
      'isMultiInput': unwrapped.isMultiInput,
      'isClearable': unwrapped.isClearable,
      'picklistItems': unwrapped.picklistItems,
      'min': unwrapped.min,
      'max': unwrapped.max,
      'defaultValue': unwrapped.defaultValue,
      'placeholder': unwrapped.placeholder,
      'description': unwrapped.description,
      'decimalPlaces': unwrapped.decimalPlaces,
      'items': unwrapped.items,
    } as DynamicFormFieldDefinitionDTO;
  }
}
