























import Vue, { PropType } from 'vue';
import SubtitleDialogViewModel from './bv-subtitle-dialog.vue.model';

export default Vue.extend({
  props: {
    value: { type: Array as PropType<string[]>, default: () => [] },
    selection: { type: Array as PropType<string[]>, default: () => [] },
    isReadOnly: { type: Boolean, default: false },
  },
  data: () => ({
    vm: new SubtitleDialogViewModel(),
  }),
  watch: {
    selection: {
      immediate: true,
      handler() {
        this.vm.selectionHandler(this.selection);
      },
    },
  },
  methods: {
    close(value: boolean) {
      if (value) {
        this.$emit('update:selection', this.vm.internalSelection);
      }
      this.$el.remove();
      this.$destroy();
    },

  },
});
