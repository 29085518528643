





































import Vue from 'vue';
import ProductListViewModel from './product-list.vue.model';

export default Vue.extend({
  auth: true,
  data: () => ({
    vm: new ProductListViewModel(),
  }),
  mounted() {
    this.vm.initialize();
  },
});
