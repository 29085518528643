import Vue from 'vue';
import apiKeysServices from '../_generated/api/api-keys';
import appServices from '../_generated/api/app';
import appLogsServices from '../_generated/api/app-logs';
import appSystemServices from '../_generated/api/app-system';
import authServices from './api/auth';
import callLogsServices from '../_generated/api/call-logs';
import contactIdMappingsServices from '../_generated/api/contact-id-mappings';
import customerOrdersServices from './api/customer-orders';
import customersServices from './api/customers';
import documentsServices from '../_generated/api/documents';
import dynamicFormServices from '../_generated/api/dynamic-form';
import employeesServices from './api/employees';
import fieldMappingServices from '../_generated/api/field-mapping';
import integrationCallsServices from '../_generated/api/integration-calls';
import integrationTasksServices from '../_generated/api/integration-tasks';
import locationsServices from '../_generated/api/locations';
import meServices from '../_generated/api/me';
import processesServices from '../_generated/api/processes';
import productCategoriesServices from './api/product-categories';
import productsServices from './api/products';
import sequencenumbersServices from '../_generated/api/sequencenumbers';
import tenantsServices from '../_generated/api/tenants';
import usersServices from './api/users';
import offlineServices from './api/offline';
import productImagesServices from './api/products-images';

export default () => ({
  apiKeys: { ...apiKeysServices(Vue.$service) },
  app: { ...appServices(Vue.$service) },
  appLogs: { ...appLogsServices(Vue.$service) },
  appSystem: { ...appSystemServices(Vue.$service) },
  auth: { ...authServices(Vue.$service, Vue.$log) },
  callLogs: { ...callLogsServices(Vue.$service) },
  contactIdMappings: { ...contactIdMappingsServices(Vue.$service) },
  customerOrders: { ...customerOrdersServices(Vue.$date, Vue.$service, Vue.$log, Vue.$date) },
  customers: { ...customersServices(Vue.$service, Vue.$log) },
  documents: { ...documentsServices(Vue.$service) },
  dynamicForm: { ...dynamicFormServices(Vue.$service) },
  employees: { ...employeesServices(Vue.$service, Vue.$log) },
  fieldMapping: { ...fieldMappingServices(Vue.$service) },
  integrationCalls: { ...integrationCallsServices(Vue.$service) },
  integrationTasks: { ...integrationTasksServices(Vue.$service) },
  locations: { ...locationsServices(Vue.$service) },
  me: { ...meServices(Vue.$service) },
  processes: { ...processesServices(Vue.$service) },
  productCategories: { ...productCategoriesServices(Vue.$service, Vue.$log) },
  products: { ...productsServices(Vue.$service, Vue.$log) },
  productImages: { ...productImagesServices(Vue.$service, Vue.$log) },
  sequencenumbers: { ...sequencenumbersServices(Vue.$service) },
  tenants: { ...tenantsServices(Vue.$service) },
  users: { ...usersServices(Vue.$service, Vue.$log, Vue.$auth) },
  offline: { ...offlineServices(Vue.$service, Vue.$log) },
});
