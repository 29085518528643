// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateCampaignFieldMappingRequestModel from '../../model/create-campaign-field-mapping-request-model';
import CreateCampaignFieldMappingRequestDTO from '../dto/create-campaign-field-mapping-request-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class CreateCampaignFieldMappingRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateCampaignFieldMappingRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.appSystem1Id = data.appSystem1Id;
      this.field1Id = data.field1Id;
      this.field1Value = data.field1Value ?? undefined;
      this.appSystem2Id = data.appSystem2Id;
      this.field2Id = data.field2Id;
      this.field2Value = data.field2Value ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  */
  'creatorName'?: string;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  */
  'modifierName'?: string;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  * @type {int32}
  */
  'appSystem1Id'?: number;
  /**
  */
  'field1Id'?: string;
  /**
  */
  'field1Value'?: string;
  /**
  * @type {int32}
  */
  'appSystem2Id'?: number;
  /**
  */
  'field2Id'?: string;
  /**
  */
  'field2Value'?: string;

  static toModel(dto: DeepPartial<CreateCampaignFieldMappingRequestDTO>): CreateCampaignFieldMappingRequestModel;
  static toModel(dto: DeepPartial<CreateCampaignFieldMappingRequestDTO> | undefined | null): CreateCampaignFieldMappingRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateCampaignFieldMappingRequestDTO> | undefined | null): CreateCampaignFieldMappingRequestModel | undefined {
    return dto ? new CreateCampaignFieldMappingRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateCampaignFieldMappingRequestModel> | CreateCampaignFieldMappingRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'creatorId': unwrapped.creatorId,
      'creatorName': unwrapped.creatorName,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modifierName': unwrapped.modifierName,
      'modified': unwrapped.modified,
      'appSystem1Id': unwrapped.appSystem1Id,
      'field1Id': unwrapped.field1Id,
      'field1Value': unwrapped.field1Value,
      'appSystem2Id': unwrapped.appSystem2Id,
      'field2Id': unwrapped.field2Id,
      'field2Value': unwrapped.field2Value,
    } as CreateCampaignFieldMappingRequestDTO;
  }
}
