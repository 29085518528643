// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import LookupProductsRequestModel from '../../model/lookup-products-request-model';
import GetProductsRequestModel from '../../model/get-products-request-model';
import GetProductRequestModel from '../../model/get-product-request-model';
import ImportProductsRequestModel from '../../model/import-products-request-model';
import ImportProductThumbnailRequestModel from '../../model/import-product-thumbnail-request-model';
import LookupPictureModifiedAfterRequestModel from '../../model/lookup-picture-modified-after-request-model';
import GetProductThumbnailRequestModel from '../../model/get-product-thumbnail-request-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';
import ProductListResultModel from '../../model/product-list-result-model';
import ProductModel from '../../model/product-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async lookupProducts(request: LookupProductsRequestModel, config?: RequestConfig) {
    const requestDTO = LookupProductsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-products'] ?? 'Products/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
  /**  */
  async getProducts(request: GetProductsRequestModel, config?: RequestConfig) {
    const requestDTO = GetProductsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-products'] ?? 'Products';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        articleNumber: requestDTO.articleNumber,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ProductListResultModel.toModel(dto));
  },
  /**  */
  async getProduct(request: GetProductRequestModel, config?: RequestConfig) {
    const requestDTO = GetProductRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-product'] ?? 'Products/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ProductModel.toModel(dto));
  },
  /**  */
  async importProducts(request: ImportProductsRequestModel, config?: RequestConfig) {
    const requestDTO = ImportProductsRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('formFile', requestDTO.formFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-import-products'] ?? 'Products/Import';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
  },
  /**  */
  async importProductThumbnail(request: ImportProductThumbnailRequestModel, config?: RequestConfig) {
    const requestDTO = ImportProductThumbnailRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('formFile', requestDTO.formFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-import-product-thumbnail'] ?? 'Products/{id}/Thumbnail';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
  },
  /**  */
  async lookupPictureModifiedAfter(request: LookupPictureModifiedAfterRequestModel, config?: RequestConfig) {
    const requestDTO = LookupPictureModifiedAfterRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-picture-modified-after'] ?? 'Products/PictureModifiedAfter/{modifiedDate}';
    endpointPath = endpointPath.replace(/\{modifiedDate\}/g, `${requestDTO.modifiedDate}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
  /**  */
  async getProductThumbnail(request: GetProductThumbnailRequestModel, config?: RequestConfig) {
    const requestDTO = GetProductThumbnailRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-product-thumbnail'] ?? 'Products/{id}/Thumbnail/{width}/{height}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{width\}/g, `${requestDTO.width}`);
    endpointPath = endpointPath.replace(/\{height\}/g, `${requestDTO.height}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
