// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetDocumentsRequestModel from '../../model/get-documents-request-model';
import GetDocumentsRequestDTO from '../dto/get-documents-request-dto';

export default abstract class GetDocumentsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetDocumentsRequestDTO>) {
    super();
    if (data) {
      this.fileExtension = data.fileExtension;
      this.searchCriteria = data.searchCriteria;
      this['paging.CurrentPage'] = data['paging.CurrentPage'];
      this['paging.PageSize'] = data['paging.PageSize'];
      this['paging.IncludeCount'] = data['paging.IncludeCount'];
      this['paging.NoPaging'] = data['paging.NoPaging'];
    }
  }
  /**
  */
  'fileExtension'?: string;
  /**
  */
  'searchCriteria'?: string;
  /**
  * @type {int32}
  */
  'paging.CurrentPage'?: number;
  /**
  * @type {int32}
  */
  'paging.PageSize'?: number;
  /**
  */
  'paging.IncludeCount'?: boolean;
  /**
  */
  'paging.NoPaging'?: boolean;

  static toModel(dto: DeepPartial<GetDocumentsRequestDTO>): GetDocumentsRequestModel;
  static toModel(dto: DeepPartial<GetDocumentsRequestDTO> | undefined | null): GetDocumentsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetDocumentsRequestDTO> | undefined | null): GetDocumentsRequestModel | undefined {
    return dto ? new GetDocumentsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetDocumentsRequestModel> | GetDocumentsRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'fileExtension': unwrapped.fileExtension,
      'searchCriteria': unwrapped.searchCriteria,
      'paging.CurrentPage': unwrapped['paging.CurrentPage'],
      'paging.PageSize': unwrapped['paging.PageSize'],
      'paging.IncludeCount': unwrapped['paging.IncludeCount'],
      'paging.NoPaging': unwrapped['paging.NoPaging'],
    } as GetDocumentsRequestDTO;
  }
}
