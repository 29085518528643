// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CustomerModel from '../../model/customer-model';
import CustomerDTO from '../dto/customer-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class CustomerModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CustomerDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.employeeId = data.employeeId ?? undefined;
      this.customerNumber = data.customerNumber ?? undefined;
      this.sapNumber = data.sapNumber ?? undefined;
      this.companyName = data.companyName ?? undefined;
      this.companyShortName = data.companyShortName ?? undefined;
      this.street = data.street ?? undefined;
      this.addressLineAddition = data.addressLineAddition ?? undefined;
      this.postalCode = data.postalCode ?? undefined;
      this.city = data.city ?? undefined;
      this.deliveryPlan = data.deliveryPlan ?? undefined;
      this.deliveryDays = data.deliveryDays ?? undefined;
      this.deliveryInformation = data.deliveryInformation ?? undefined;
      this.isActive = data.isActive;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  */
  'creatorName'?: string;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  */
  'modifierName'?: string;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  * @type {int64}
  */
  'employeeId'?: number;
  /**
  */
  'customerNumber'?: string;
  /**
  */
  'sapNumber'?: string;
  /**
  */
  'companyName'?: string;
  /**
  */
  'companyShortName'?: string;
  /**
  */
  'street'?: string;
  /**
  */
  'addressLineAddition'?: string;
  /**
  */
  'postalCode'?: string;
  /**
  */
  'city'?: string;
  /**
  */
  'deliveryPlan'?: string;
  /**
  */
  'deliveryDays'?: string;
  /**
  */
  'deliveryInformation'?: string;
  /**
  */
  'isActive'?: boolean;

  static toModel(dto: DeepPartial<CustomerDTO>): CustomerModel;
  static toModel(dto: DeepPartial<CustomerDTO> | undefined | null): CustomerModel | undefined;
  static toModel(dto: DeepPartial<CustomerDTO> | undefined | null): CustomerModel | undefined {
    return dto ? new CustomerModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CustomerModel> | CustomerModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'creatorId': unwrapped.creatorId,
      'creatorName': unwrapped.creatorName,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modifierName': unwrapped.modifierName,
      'modified': unwrapped.modified,
      'employeeId': unwrapped.employeeId,
      'customerNumber': unwrapped.customerNumber,
      'sapNumber': unwrapped.sapNumber,
      'companyName': unwrapped.companyName,
      'companyShortName': unwrapped.companyShortName,
      'street': unwrapped.street,
      'addressLineAddition': unwrapped.addressLineAddition,
      'postalCode': unwrapped.postalCode,
      'city': unwrapped.city,
      'deliveryPlan': unwrapped.deliveryPlan,
      'deliveryDays': unwrapped.deliveryDays,
      'deliveryInformation': unwrapped.deliveryInformation,
      'isActive': unwrapped.isActive,
    } as CustomerDTO;
  }
}
