// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CallLogModel from '../../model/call-log-model';
import CallLogDTO from '../dto/call-log-dto';

export default abstract class CallLogModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CallLogDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.creatorId = data.creatorId;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modified = data.modified ?? undefined;
      this.targetSystemName = data.targetSystemName ?? undefined;
      this.correlationId = data.correlationId ?? undefined;
      this.requestTime = data.requestTime;
      this.requestType = data.requestType ?? undefined;
      this.requestUrl = data.requestUrl ?? undefined;
      this.requestBody = data.requestBody ?? undefined;
      this.responseTime = data.responseTime ?? undefined;
      this.responseStatusCode = data.responseStatusCode ?? undefined;
      this.responseBody = data.responseBody ?? undefined;
      this.responseInfo = data.responseInfo ?? undefined;
      this.exceptionMessage = data.exceptionMessage ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  */
  'targetSystemName'?: string;
  /**
  */
  'correlationId'?: string;
  /**
  * @type {date-time}
  */
  'requestTime'?: string;
  /**
  */
  'requestType'?: string;
  /**
  */
  'requestUrl'?: string;
  /**
  */
  'requestBody'?: string;
  /**
  * @type {date-time}
  */
  'responseTime'?: string;
  /**
  * @type {int32}
  */
  'responseStatusCode'?: number;
  /**
  */
  'responseBody'?: string;
  /**
  */
  'responseInfo'?: string;
  /**
  */
  'exceptionMessage'?: string;

  static toModel(dto: DeepPartial<CallLogDTO>): CallLogModel;
  static toModel(dto: DeepPartial<CallLogDTO> | undefined | null): CallLogModel | undefined;
  static toModel(dto: DeepPartial<CallLogDTO> | undefined | null): CallLogModel | undefined {
    return dto ? new CallLogModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CallLogModel> | CallLogModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'creatorId': unwrapped.creatorId,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modified': unwrapped.modified,
      'targetSystemName': unwrapped.targetSystemName,
      'correlationId': unwrapped.correlationId,
      'requestTime': unwrapped.requestTime,
      'requestType': unwrapped.requestType,
      'requestUrl': unwrapped.requestUrl,
      'requestBody': unwrapped.requestBody,
      'responseTime': unwrapped.responseTime,
      'responseStatusCode': unwrapped.responseStatusCode,
      'responseBody': unwrapped.responseBody,
      'responseInfo': unwrapped.responseInfo,
      'exceptionMessage': unwrapped.exceptionMessage,
    } as CallLogDTO;
  }
}
