// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetProductRequestModel from '../../model/get-product-request-model';
import GetProductRequestDTO from '../dto/get-product-request-dto';

export default abstract class GetProductRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetProductRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;

  static toModel(dto: DeepPartial<GetProductRequestDTO>): GetProductRequestModel;
  static toModel(dto: DeepPartial<GetProductRequestDTO> | undefined | null): GetProductRequestModel | undefined;
  static toModel(dto: DeepPartial<GetProductRequestDTO> | undefined | null): GetProductRequestModel | undefined {
    return dto ? new GetProductRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetProductRequestModel> | GetProductRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
    } as GetProductRequestDTO;
  }
}
