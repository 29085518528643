// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import EmployeeModel from '../../model/employee-model';
import EmployeeDTO from '../dto/employee-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class EmployeeModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<EmployeeDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.userId = data.userId ?? undefined;
      this.employeeNumber = data.employeeNumber;
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.email = data.email ?? undefined;
      this.isActive = data.isActive;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  */
  'creatorName'?: string;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  */
  'modifierName'?: string;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  * @type {int64}
  */
  'userId'?: number;
  /**
  * @type {int32}
  */
  'employeeNumber'?: number;
  /**
  */
  'firstName'?: string;
  /**
  */
  'lastName'?: string;
  /**
  */
  'email'?: string;
  /**
  */
  'isActive'?: boolean;

  static toModel(dto: DeepPartial<EmployeeDTO>): EmployeeModel;
  static toModel(dto: DeepPartial<EmployeeDTO> | undefined | null): EmployeeModel | undefined;
  static toModel(dto: DeepPartial<EmployeeDTO> | undefined | null): EmployeeModel | undefined {
    return dto ? new EmployeeModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<EmployeeModel> | EmployeeModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'creatorId': unwrapped.creatorId,
      'creatorName': unwrapped.creatorName,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modifierName': unwrapped.modifierName,
      'modified': unwrapped.modified,
      'userId': unwrapped.userId,
      'employeeNumber': unwrapped.employeeNumber,
      'firstName': unwrapped.firstName,
      'lastName': unwrapped.lastName,
      'email': unwrapped.email,
      'isActive': unwrapped.isActive,
    } as EmployeeDTO;
  }
}
