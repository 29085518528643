// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ImportProductsRequestModel from '../../model/import-products-request-model';
import ImportProductsRequestDTO from '../dto/import-products-request-dto';

export default abstract class ImportProductsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ImportProductsRequestDTO>) {
    super();
    if (data) {
      this.formFile = data.formFile;
    }
  }
  /**
  * @type {binary}
  */
  'formFile'?: File;

  static toModel(dto: DeepPartial<ImportProductsRequestDTO>): ImportProductsRequestModel;
  static toModel(dto: DeepPartial<ImportProductsRequestDTO> | undefined | null): ImportProductsRequestModel | undefined;
  static toModel(dto: DeepPartial<ImportProductsRequestDTO> | undefined | null): ImportProductsRequestModel | undefined {
    return dto ? new ImportProductsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ImportProductsRequestModel> | ImportProductsRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'formFile': unwrapped.formFile,
    } as ImportProductsRequestDTO;
  }
}
