import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';

export default class SubtitleDialogViewModel extends ViewModelBase {
  internalSelection = [] as string[];
  initialize(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  getIsChecked(subtitle: string) {
    return this.internalSelection.includes(subtitle);
  }

  setSubtitleSelected(checked: boolean, subtitle: string) {
    if (this.internalSelection.includes(subtitle) && !checked) {
      this.internalSelection = this.internalSelection.filter((e) => e !== subtitle) as string[];
    } else if (!this.internalSelection.includes(subtitle) && checked) {
      this.internalSelection = [...this.internalSelection, subtitle] as string[];
    }
  }
  selectionHandler(selection: Array<string>) {
    if (selection) {
      this.internalSelection = selection as string[];
    }
  }
}
