// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetEmployeeByUserIdRequestModel from '../../model/get-employee-by-user-id-request-model';
import GetEmployeeByUserIdRequestDTO from '../dto/get-employee-by-user-id-request-dto';

export default abstract class GetEmployeeByUserIdRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetEmployeeByUserIdRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId;
    }
  }
  /**
  * @type {int64}
  */
  'userId'?: number;

  static toModel(dto: DeepPartial<GetEmployeeByUserIdRequestDTO>): GetEmployeeByUserIdRequestModel;
  static toModel(dto: DeepPartial<GetEmployeeByUserIdRequestDTO> | undefined | null): GetEmployeeByUserIdRequestModel | undefined;
  static toModel(dto: DeepPartial<GetEmployeeByUserIdRequestDTO> | undefined | null): GetEmployeeByUserIdRequestModel | undefined {
    return dto ? new GetEmployeeByUserIdRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetEmployeeByUserIdRequestModel> | GetEmployeeByUserIdRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'userId': unwrapped.userId,
    } as GetEmployeeByUserIdRequestDTO;
  }
}
