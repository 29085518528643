// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import IntegrationTaskModel from '../../model/integration-task-model';
import IntegrationTaskDTO from '../dto/integration-task-dto';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class IntegrationTaskModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<IntegrationTaskDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.name = data.name ?? undefined;
      this.description = data.description ?? undefined;
      this.integrationTaskTypeId = data.integrationTaskTypeId;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  'creatorId'?: number;
  /**
  */
  'creatorName'?: string;
  /**
  * @type {date-time}
  */
  'created'?: string;
  /**
  * @type {int64}
  */
  'modifierId'?: number;
  /**
  */
  'modifierName'?: string;
  /**
  * @type {date-time}
  */
  'modified'?: string;
  /**
  */
  'name'?: string;
  /**
  */
  'description'?: string;
  /**
  * @type {int32}
  */
  'integrationTaskTypeId'?: number;

  static toModel(dto: DeepPartial<IntegrationTaskDTO>): IntegrationTaskModel;
  static toModel(dto: DeepPartial<IntegrationTaskDTO> | undefined | null): IntegrationTaskModel | undefined;
  static toModel(dto: DeepPartial<IntegrationTaskDTO> | undefined | null): IntegrationTaskModel | undefined {
    return dto ? new IntegrationTaskModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<IntegrationTaskModel> | IntegrationTaskModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'links': unwrapped.links,
      'creatorId': unwrapped.creatorId,
      'creatorName': unwrapped.creatorName,
      'created': unwrapped.created,
      'modifierId': unwrapped.modifierId,
      'modifierName': unwrapped.modifierName,
      'modified': unwrapped.modified,
      'name': unwrapped.name,
      'description': unwrapped.description,
      'integrationTaskTypeId': unwrapped.integrationTaskTypeId,
    } as IntegrationTaskDTO;
  }
}
