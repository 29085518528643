// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetEmployeeByUserIdRequestModel from '../../model/get-employee-by-user-id-request-model';
import ImportEmployeesRequestModel from '../../model/import-employees-request-model';
import EmployeeModel from '../../model/employee-model';

export default (service: Servicelayer) => ({
  /**  */
  async getEmployeeByUserId(request: GetEmployeeByUserIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetEmployeeByUserIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-employee-by-user-id'] ?? 'Employees/{userId}/Employee';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(EmployeeModel.toModel(dto));
  },
  /**  */
  async importEmployees(request: ImportEmployeesRequestModel, config?: RequestConfig) {
    const requestDTO = ImportEmployeesRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('formFile', requestDTO.formFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-import-employees'] ?? 'Employees/Import';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
  },
});
