// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SetUserStatusRequestModel from '../../model/set-user-status-request-model';
import SetUserStatusRequestDTO from '../dto/set-user-status-request-dto';

export default abstract class SetUserStatusRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SetUserStatusRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.newStatusId = data.newStatusId;
    }
  }
  /**
  * @type {int64}
  */
  'id'?: number;
  /**
  * @type {int32}
  */
  'newStatusId'?: number;

  static toModel(dto: DeepPartial<SetUserStatusRequestDTO>): SetUserStatusRequestModel;
  static toModel(dto: DeepPartial<SetUserStatusRequestDTO> | undefined | null): SetUserStatusRequestModel | undefined;
  static toModel(dto: DeepPartial<SetUserStatusRequestDTO> | undefined | null): SetUserStatusRequestModel | undefined {
    return dto ? new SetUserStatusRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SetUserStatusRequestModel> | SetUserStatusRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'newStatusId': unwrapped.newStatusId,
    } as SetUserStatusRequestDTO;
  }
}
