// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CampaignCustomFieldModel from '../../model/campaign-custom-field-model';
import CampaignCustomFieldDTO from '../dto/campaign-custom-field-dto';

export default abstract class CampaignCustomFieldModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CampaignCustomFieldDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.appSystemId = data.appSystemId;
      this.caption = data.caption ?? undefined;
      this.type = data.type ?? undefined;
    }
  }
  /**
  */
  'id'?: string;
  /**
  * @type {int64}
  */
  'appSystemId'?: number;
  /**
  */
  'caption'?: string;
  /**
  */
  'type'?: string;

  static toModel(dto: DeepPartial<CampaignCustomFieldDTO>): CampaignCustomFieldModel;
  static toModel(dto: DeepPartial<CampaignCustomFieldDTO> | undefined | null): CampaignCustomFieldModel | undefined;
  static toModel(dto: DeepPartial<CampaignCustomFieldDTO> | undefined | null): CampaignCustomFieldModel | undefined {
    return dto ? new CampaignCustomFieldModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CampaignCustomFieldModel> | CampaignCustomFieldModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'appSystemId': unwrapped.appSystemId,
      'caption': unwrapped.caption,
      'type': unwrapped.type,
    } as CampaignCustomFieldDTO;
  }
}
