










import Vue from 'vue';

export default Vue.extend({
  name: 'IlLazy',
  props: {
    renderOnIdle: { type: Boolean, default: false },
    unrender: { type: Boolean, default: false },
    minHeight: { type: Number, default: undefined },
    unrenderDelay: { type: Number, default: undefined },
  },
  data: () => ({
    shouldRender: false,
    targetEl: undefined as undefined | HTMLElement,
    unrenderTimeoutHndl: undefined as number | undefined,
    renderTimeoutHndl: undefined as number | undefined,
    intersectionObserver: undefined as IntersectionObserver | undefined,
  }),
  mounted() {
    const appEl = this._.component.getAppEl();
    const options = {
      root: appEl,
    };
    if (typeof window.requestIdleCallback === 'function' && this.renderOnIdle) {
      window.requestIdleCallback(() => { this.shouldRender = true; });
    }
    this.intersectionObserver = new IntersectionObserver(this.intersectionCallback, options);
    this.intersectionObserver.observe(this.$refs.targetEl as HTMLElement);
  },
  beforeDestroy() {
    this.intersectionObserver?.disconnect();
  },
  methods: {
    intersectionCallback(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
      const [entry] = entries;
      if (entry.isIntersecting) {
        clearTimeout(this.unrenderTimeoutHndl);
        if (this.unrender) {
          this.renderTimeoutHndl = setTimeout(() => {
            // Avoid extreme loading and unloading because of fast scrolling
            this.shouldRender = true;
          }, 50);
        } else {
          this.shouldRender = true;
          observer?.disconnect();
          this.intersectionObserver?.disconnect();
        }
      } else if (this.unrender) {
        clearTimeout(this.renderTimeoutHndl);
        this.unrenderTimeoutHndl = setTimeout(() => {
          this.shouldRender = false;
        }, this.unrenderDelay ?? 300);
      }
    },
  },
});
