// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ForgotPasswordRequestModel from '../../model/forgot-password-request-model';
import ForgotPasswordRequestDTO from '../dto/forgot-password-request-dto';

export default abstract class ForgotPasswordRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ForgotPasswordRequestDTO>) {
    super();
    if (data) {
      this.targetUrl = data.targetUrl;
      this.eMail = data.eMail;
    }
  }
  /**
  */
  'targetUrl'?: string;
  /**
  * @type {email}
  */
  'eMail'?: string;

  static toModel(dto: DeepPartial<ForgotPasswordRequestDTO>): ForgotPasswordRequestModel;
  static toModel(dto: DeepPartial<ForgotPasswordRequestDTO> | undefined | null): ForgotPasswordRequestModel | undefined;
  static toModel(dto: DeepPartial<ForgotPasswordRequestDTO> | undefined | null): ForgotPasswordRequestModel | undefined {
    return dto ? new ForgotPasswordRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ForgotPasswordRequestModel> | ForgotPasswordRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'targetUrl': unwrapped.targetUrl,
      'eMail': unwrapped.eMail,
    } as ForgotPasswordRequestDTO;
  }
}
