














import Vue from 'vue';
import CustomerLookupViewModel from './bv-customer-lookup.vue.model';

export default Vue.extend({
  props: {
    value: { type: Number, default: undefined, required: false },
    employeeId: { type: Number, default: undefined, required: false },
  },
  data: () => ({
    vm: new CustomerLookupViewModel(),
  }),
  watch: {
    employeeId(newVal:number|undefined, oldVal:number|undefined) {
      if (newVal !== oldVal) {
        this.vm.customersLookupDataSource.filter.employeeId = newVal;
        this.vm.customersLookupDataSource.select();
      }
    },
  },
  mounted() {
    this.vm.initialize();
    this.vm.customersLookupDataSource.filter.employeeId = this.employeeId;
    this.vm.customersLookupDataSource.filter.id = this.value;
    this.vm.customersLookupDataSource.select();
    // this.$emit('input', this.value);
  },
  methods: {
    onFocusOut() {
      if (this.vm.customersLookupDataSource.data?.items.length === 1 && this.vm.customersLookupDataSource.filter.searchCriteria !== '') {
        this.$emit('input', this.vm.customersLookupDataSource.data?.items[0].data.id);
      }
    },
    onInput(value: string) {
      if (value === undefined) {
        this.vm.customersLookupDataSource.filter.searchCriteria = '';
        this.vm.customersLookupDataSource.select();
      }
      this.$emit('input', value);
    },
    onSearch(value: string) {
      this.vm.customersLookupDataSource.filter.employeeId = this.employeeId;
      this.vm.customersLookupDataSource.filter.searchCriteria = value;
      this.vm.customersLookupDataSource.filter.id = undefined;
      this.vm.customersLookupDataSource.select();
    },
  },
});
