// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import IntegrationTaskListResultModel from '../../model/integration-task-list-result-model';
import IntegrationTaskListResultDTO from '../dto/integration-task-list-result-dto';
import IntegrationTaskModel from '../../model/integration-task-model';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class IntegrationTaskListResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<IntegrationTaskListResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => IntegrationTaskModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  'items'?: IntegrationTaskModel[];
  /**
  */
  'links'?: ResourceLinkModel[];

  static toModel(dto: DeepPartial<IntegrationTaskListResultDTO>): IntegrationTaskListResultModel;
  static toModel(dto: DeepPartial<IntegrationTaskListResultDTO> | undefined | null): IntegrationTaskListResultModel | undefined;
  static toModel(dto: DeepPartial<IntegrationTaskListResultDTO> | undefined | null): IntegrationTaskListResultModel | undefined {
    return dto ? new IntegrationTaskListResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<IntegrationTaskListResultModel> | IntegrationTaskListResultModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'items': unwrapped.items,
      'links': unwrapped.links,
    } as IntegrationTaskListResultDTO;
  }
}
