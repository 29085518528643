import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import Vue, { VueConstructor } from 'vue';
// import { CoreOptions } from '@glittr/frontend-core/index';

export class AlertHandler {
  errorMessages: string[] = [];
  warningMessages: string[] = [];
  infoMessages: string[] = [];

  constructor(app: VueConstructor | Vue) {
    app.$router.beforeEach((to, from, next) => { this.clear(); next(); });
  }

  clear() {
    this.errorMessages = [];
    this.warningMessages = [];
    this.infoMessages = [];
  }

  error(value: string | Error | ServiceResponse) {
    const message = ((value as Error | ServiceResponse)?.message ?? value) as string;
    this.errorMessages.push(message);
    Vue.$log.error(message);
    Vue.$log.error(value);
  }

  warning(value: string | Error | ServiceResponse) {
    const message = ((value as Error | ServiceResponse)?.message ?? value) as string;
    this.warningMessages.push(message);
    Vue.$log.warning(message);
    Vue.$log.warning(value);
  }

  info(value: string | Error | ServiceResponse) {
    const message = ((value as Error | ServiceResponse)?.message ?? value) as string;
    this.infoMessages.push(message);
    Vue.$log.info(message);
    Vue.$log.info(value);
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $alert: AlertHandler
  }
  // eslint-disable-next-line no-shadow
  interface VueConstructor {
    $alert: AlertHandler
  }
}

export default {
  install: (app: VueConstructor) => {
    app.$alert = new AlertHandler(app);
    app.prototype.$alert = app.$alert;
  },
};
