import Vue from 'vue';
import DataRecord, { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import GetEmployeeByUserIdRequestModel from '../../model/get-employee-by-user-id-request-model';
import Int64LookupModel from '../../model/int64-lookup-model';
import LookupPictureModifiedAfterRequestModel from '../../model/lookup-picture-modified-after-request-model';
import CacheSettings from './cache-settings';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';
import OfflineCachingViewModel from '../../../../views/pages/app/settings/offline-caching.vue.model';
import CustomerOrderListPagingResultModel from '../../model/customer-order-list-paging-result-model';
import CustomerOrderListModel from '../../model/customer-order-list-model';
import GetMeRequestModel from '../../model/get-me-request-model';

export default (service: Servicelayer, log: Logger) => {
  const baseService = service;
  const cacheSettings = new CacheSettings();
  type ProgressListenerCallback = (scope: OfflineCachingViewModel, percent: number, currentStep: number, totalSteps: number) => void;
  let progressListeners: ProgressListenerCallback[] = [];
  let isOfflineCacheRunning = false;
  const callAllProgressListeners = (scope: OfflineCachingViewModel, percent: number, currentStep: number, totalSteps: number) => {
    progressListeners.forEach((listener: ProgressListenerCallback) => {
      listener(scope, percent, currentStep, totalSteps);
    });
    if (percent >= 1) {
      isOfflineCacheRunning = false;
      cacheSettings.set<Date>('ProductImagesLastDownloaded', new Date());
      progressListeners = [];
    }
  };

  return {

    async areResourcesCached() {
      const ret = (await caches.has('api-images'))
        && (await caches.has('api-cache'));
      return ret;
    },
    areResourcesPreloadingForOffline() {
      return isOfflineCacheRunning;
    },
    async preloadResourcesForOffline(scope: OfflineCachingViewModel, progressCallback: ProgressListenerCallback) {
      if (isOfflineCacheRunning) {
        // Don't load resources multiple times
        return;
      }
      isOfflineCacheRunning = true;
      await caches.delete('api-cache');
      progressListeners.push(progressCallback);
      await baseService.v2.api.me.getMe(new GetMeRequestModel());
      const progress = {
        get percent() { return this.currentStep / this.totalSteps; },
        currentStep: 0,
        totalSteps: 6,
      };

      await baseService.v2.api.customerOrders.getCustomerOrderList(true, {}, {})
        .then((response: DataRecordWrapResponse<CustomerOrderListPagingResultModel>) => {
          progress.currentStep += 1;
          progress.totalSteps += response.items.length;
          callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
          response.items.forEach((order: DataRecord<CustomerOrderListModel>) => {
            baseService.v2.api.customerOrders.getCustomerOrdersById(true, { id: order.data.id }, {}).finally(() => {
              progress.currentStep += 1;
              callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
            });
          });
        });
      await baseService.v2.api.customerOrders.getCustomerOrderList(false, {}, {})
        .finally(() => {
          progress.currentStep += 1;
          callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
        });
      await baseService.v2.api.products.getProducts(true, {}, {})
        .finally(() => {
          progress.currentStep += 1;
          callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
        });

      const lastDownloaded = new Date(cacheSettings.get<Date>('ProductImagesLastDownloaded', new Date(1900, 0, 1)));

      await baseService.v2.api.productImages.getProductImageModifiedAfter(LookupPictureModifiedAfterRequestModel.toModel({ modifiedDate: lastDownloaded.toISOString() }), undefined)
        .then((response: DataRecordWrapResponse<Int64LookupListResultModel>) => {
          progress.currentStep += 1;
          const filteredItems = response.items.filter((product: DataRecord<Int64LookupModel>) => product.data.id);
          progress.totalSteps += filteredItems.length;
          callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
          const throttleDownload = 150;
          let i = 0;
          filteredItems.forEach((product: DataRecord<Int64LookupModel>) => {
            setTimeout(async () => {
              if (await caches.has('api-images')) {
                const id = +product.data.id!;
                const cache = (await caches.open('api-images'));
                const endpointPath = (Vue.$config.values as any)['endpoints-import-product-thumbnail'] ?? `Products/${id}/Thumbnail`;
                const cacheItem = (await cache.keys()).filter((x) => x.url.indexOf(endpointPath) > 0);
                // eslint-disable-next-line no-restricted-syntax
                for (const element of cacheItem) {
                  // eslint-disable-next-line no-await-in-loop
                  await cache.delete(element?.url!);
                }
              }

              await baseService.v2.api.productImages.getProductImageForceOnline(true, +product.data.id!, {})
                .finally(() => {
                  progress.currentStep += 1;
                  callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
                });
            }, throttleDownload * i);
            i += 1;
          });
          if (filteredItems.length === 0) {
            progress.currentStep += 1;
          }
        });

      await baseService.v2.api.productCategories.getProductCategories(true, {}, {})
        .finally(() => {
          progress.currentStep += 1;
          callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
        });

      await baseService.v2.api.customers.getAllCustomers(true, {}, {})
        .finally(() => {
          progress.currentStep += 1;
          callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
        });

      await baseService.v2.api.customers.lookupCustomers(true, {}, {})
        .finally(() => {
          progress.currentStep += 1;
          callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
        });

      const user = await baseService.v2.api.users.getUser(true);
      if (user) {
        await baseService.v2.api.employees.getEmployeeByUserId(true, GetEmployeeByUserIdRequestModel.toModel({
          userId: user.id!,
        }))
          .finally(() => {
            progress.currentStep += 1;
            callAllProgressListeners(scope, progress.percent, progress.currentStep, progress.totalSteps);
          });
      } else {
        log.error(new Error('page.offlineCaching.label.unableToLoadUser'));
        throw new Error('page.offlineCaching.label.unableToLoadUser');
      }
    },
  };
};
