






























import Vue from 'vue';
import OfflineCachingViewModel from './offline-caching.vue.model';

export default Vue.extend({
  auth: true,
  data: () => ({
    vm: new OfflineCachingViewModel(),
  }),
  mounted() {
    this.vm.initialize();
  },
});
