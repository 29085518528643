import Vue from 'vue';

interface KeyValue<T> {
  key: string;
  value: T;
}
export default class CacheObjects {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private CACHENAME: string = 'OBJECTS') {}
  get<T>(key:string, defaultvalue: T):T {
    const ret = Vue.$localStorage.get<KeyValue<T>[]>(this.CACHENAME)?.filter((x) => x.key === key)[0];
    return ret?.value ?? defaultvalue;
  }

  set<T>(key:string, defaultvalue: T):void {
    let storageItems = Vue.$localStorage.get<KeyValue<T>[]>(this.CACHENAME) ?? [];
    storageItems = storageItems.filter((x) => key !== x.key);
    storageItems.push({ key, value: defaultvalue });
    Vue.$localStorage.set(this.CACHENAME, storageItems);
  }

  remove<T>(key:string) {
    let storageItems = Vue.$localStorage.get<KeyValue<T>[]>(this.CACHENAME) ?? [];
    storageItems = storageItems.filter((x) => key !== x.key);
    Vue.$localStorage.set(this.CACHENAME, storageItems);
  }
}
