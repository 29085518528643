import Vue from 'vue';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import Int64LookupPagingResultModel from '../../services/v2/model/int64-lookup-paging-result-model';
import LookupCustomersRequestModel from '../../services/v2/model/lookup-customers-request-model';
import Int64LookupModel from '../../services/v2/model/int64-lookup-model';

export default class CustomerLookupViewModel extends ViewModelBase {
  filter: {
    searchCriteria?: string
  } = {};
  customersLookupDataSource = new DataSource({
    selectCommand: (request: LookupCustomersRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<Int64LookupPagingResultModel>> => Vue.$service.v2.api.customers.lookupCustomers(Vue.$pwa.isOnline, request, config),
  });

  get items(): Int64LookupModel[] {
    return this.customersLookupDataSource.data?.items.map((i) => i.data as Int64LookupModel) ?? [];
  }

  async initialize(): Promise<void> {
    await this.customersLookupDataSource.initialize();
  }
}
