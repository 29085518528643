import Vue from 'vue';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import DataEvent from '@glittr/frontend-core/src/core/v2/data/data-event';
import BvSubtitleDialog from '../forms/bv-subtitle-dialog.vue';

export default class ProductListEntryViewModel extends ViewModelBase {
  onSubtitleSelected = new DataEvent<[string[]]>();
  // eslint-disable-next-line no-empty-function
  async initialize(): Promise<void> { }

  openSubtitles(value: any, isReadOnly: boolean) {
    const subtitleDialog = new BvSubtitleDialog({
      propsData: {
        isReadOnly,
        value: isReadOnly && value.selectedTitles ? value.selectedTitles : value.subTitles,
        selection: value.selectedTitles,
      },
    });
    subtitleDialog.$on('update:selection', (sel: string[]) => {
      if (isReadOnly) return;
      value.selectedTitles = sel;
      this.onSubtitleSelected.invoke(sel);
    });
    Vue.$dialog.mountDialog(subtitleDialog);
  }
}
