// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AppLogPagingResultModel from '../../model/app-log-paging-result-model';
import AppLogPagingResultDTO from '../dto/app-log-paging-result-dto';
import AppLogModel from '../../model/app-log-model';
import ResourceLinkModel from '../../model/resource-link-model';
import PaginationDataModel from '../../model/pagination-data-model';

export default abstract class AppLogPagingResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AppLogPagingResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => AppLogModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.paginationData = data.paginationData ? PaginationDataModel.toModel(data.paginationData) : undefined;
    }
  }
  /**
  */
  'items'?: AppLogModel[];
  /**
  */
  'links'?: ResourceLinkModel[];
  /**
  */
  'paginationData'?: PaginationDataModel;

  static toModel(dto: DeepPartial<AppLogPagingResultDTO>): AppLogPagingResultModel;
  static toModel(dto: DeepPartial<AppLogPagingResultDTO> | undefined | null): AppLogPagingResultModel | undefined;
  static toModel(dto: DeepPartial<AppLogPagingResultDTO> | undefined | null): AppLogPagingResultModel | undefined {
    return dto ? new AppLogPagingResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AppLogPagingResultModel> | AppLogPagingResultModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'items': unwrapped.items,
      'links': unwrapped.links,
      'paginationData': unwrapped.paginationData,
    } as AppLogPagingResultDTO;
  }
}
