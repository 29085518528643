// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormPicklistItemDefinitionModel from '../../model/dynamic-form-picklist-item-definition-model';
import DynamicFormPicklistItemDefinitionDTO from '../dto/dynamic-form-picklist-item-definition-dto';

export default abstract class DynamicFormPicklistItemDefinitionModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormPicklistItemDefinitionDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.caption = data.caption ?? undefined;
      this.order = data.order;
    }
  }
  /**
  */
  'id'?: string;
  /**
  */
  'caption'?: string;
  /**
  * @type {int32}
  */
  'order'?: number;

  static toModel(dto: DeepPartial<DynamicFormPicklistItemDefinitionDTO>): DynamicFormPicklistItemDefinitionModel;
  static toModel(dto: DeepPartial<DynamicFormPicklistItemDefinitionDTO> | undefined | null): DynamicFormPicklistItemDefinitionModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormPicklistItemDefinitionDTO> | undefined | null): DynamicFormPicklistItemDefinitionModel | undefined {
    return dto ? new DynamicFormPicklistItemDefinitionModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormPicklistItemDefinitionModel> | DynamicFormPicklistItemDefinitionModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'id': unwrapped.id,
      'caption': unwrapped.caption,
      'order': unwrapped.order,
    } as DynamicFormPicklistItemDefinitionDTO;
  }
}
