// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SetPasswordRequestModel from '../../model/set-password-request-model';
import SetPasswordRequestDTO from '../dto/set-password-request-dto';

export default abstract class SetPasswordRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SetPasswordRequestDTO>) {
    super();
    if (data) {
      this.email = data.email;
      this.oldPassword = data.oldPassword;
      this.newPassword = data.newPassword;
    }
  }
  /**
  * @type {email}
  */
  'email'?: string;
  /**
  */
  'oldPassword'?: string;
  /**
  */
  'newPassword'?: string;

  static toModel(dto: DeepPartial<SetPasswordRequestDTO>): SetPasswordRequestModel;
  static toModel(dto: DeepPartial<SetPasswordRequestDTO> | undefined | null): SetPasswordRequestModel | undefined;
  static toModel(dto: DeepPartial<SetPasswordRequestDTO> | undefined | null): SetPasswordRequestModel | undefined {
    return dto ? new SetPasswordRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SetPasswordRequestModel> | SetPasswordRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'email': unwrapped.email,
      'oldPassword': unwrapped.oldPassword,
      'newPassword': unwrapped.newPassword,
    } as SetPasswordRequestDTO;
  }
}
