import Vue from 'vue';
import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import DataRecord, { DataRecordWrapResponse, wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetProductsRequestModel from '../../model/get-products-request-model';
import ImportProductThumbnailRequestModel from '../../model/import-product-thumbnail-request-model';
import ImportProductsRequestModel from '../../model/import-products-request-model';
import LookupProductsRequestModel from '../../model/lookup-products-request-model';
import productsServices from '../../_generated/api/products';
import productCategoriesServices from '../../_generated/api/product-categories';
import ProductModel from '../../model/product-model';
import CustomerOrderModel from '../../model/customer-order-model';
import OrderItemArticleModel from '../../model/order-item-article-model';
import ProductCategoryModel from '../../model/product-category-model';
import CategoryOrderItemArticleListResultModel from '../../model/category-order-item-article-model';
import OrderItemArticleListResultModel from '../../model/order-item-article-list-result-model';
import Int64LookupModel from '../../model/int64-lookup-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';
import GetProductRequestModel from '../../model/get-product-request-model';

export default (service: Servicelayer, log: Logger) => {
  const wrapResultWithDataRecord = <T>(res: T): DataRecordWrapResponse<T> => {
    let clone = JSON.parse(JSON.stringify(res));
    if (Array.isArray(clone?.items)) clone.items = clone.items.map((i: any) => new DataRecord(i));
    else clone = new DataRecord(clone);
    return clone;
  };

  const baseProductCategoriesService = productCategoriesServices(service);
  const baseService = productsServices(service);
  const filterLookup = (items: ProductModel[], request: LookupProductsRequestModel): Int64LookupModel[] => {
    return items
      .filter((i) => (request.id || i.id) === i.id)
      .map((product) => {
        return {
          id: product?.id,
          caption: `${product?.articleNumber} - ${product?.title}`,
          details: `${product?.text}`,
        } as Int64LookupModel;
      });
  };
  return {
    async lookupProducts(isOnline: boolean, request: LookupProductsRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<Int64LookupPagingResultModel>> {
      if (!isOnline) {
        const ret = await this.getProducts(isOnline, GetProductsRequestModel.toModel({ searchCriteria: request.searchCriteria }), config);

        const items = filterLookup(ret.items.map((p) => p.data!), request);
        return wrapResponseWithDataRecord({
          items,
        });
      }
      const ret = await baseService.lookupProducts(request, config);
      return ret;
    },
    async getProductsForOrderGroupedByCategories(isOnline: boolean, request: GetProductsRequestModel, config?: RequestConfig, order?: CustomerOrderModel, categoryId?: number, doLoadAllArticles: boolean = true):
      Promise<CategoryOrderItemArticleListResultModel[]> {
      const products = await this.getProductsForOrder(isOnline, request, config, order, doLoadAllArticles);
      const productCategories = await baseProductCategoriesService.getProductCategories({}, undefined);
      const categories = productCategories.items.filter((x) => !categoryId || x.data.id === categoryId)[0]?.data.subCategories!;
      const ret = this.structureByCategory(products.items, categories);
      return this.flattenGroupedByCategories(ret).filter((x) => x.items.length > 0);
    },
    structureByCategory(
      products: DataRecord<OrderItemArticleModel>[],
      productCategories: ProductCategoryModel[],
    )
      : CategoryOrderItemArticleListResultModel[] {
      return (productCategories ?? [])
        .filter((s) => (s.productIds || []).length > 0 || (s.subCategories || []).length > 0)
        .map((cat) => {
          return {
            category: cat,
            items: [...products.filter((p) => (cat.productIds || []).indexOf(p.data.productId!) > -1).map((a) => a.data)],
            subCategories: this.structureByCategory(products, cat.subCategories || []),
          } as unknown as CategoryOrderItemArticleListResultModel;
        }).filter((y) => y.items.length > 0 || y.subCategories.length > 0);
    },
    flattenGroupedByCategories(items: CategoryOrderItemArticleListResultModel[]): CategoryOrderItemArticleListResultModel[] {
      const returnArray: CategoryOrderItemArticleListResultModel[] = [];
      for (let index = 0; index < items.length; index += 1) {
        const element = items[index];
        returnArray.push(element);
        if (element.subCategories) {
          const subCategories = this.flattenGroupedByCategories(element.subCategories);
          for (let indexInner = 0; indexInner < subCategories.length; indexInner += 1) {
            returnArray.push(subCategories[indexInner]);
          }
        }
      }
      return returnArray;
    },
    async getProductsForOrder(isOnline: boolean, request: GetProductsRequestModel, config?: RequestConfig, order?: CustomerOrderModel, doLoadAllArticles: boolean = true): Promise<DataRecordWrapResponse<OrderItemArticleListResultModel>> {
      const products = (await this.getProducts(isOnline, request, config));
      if (order?.orderItems || doLoadAllArticles) {
        products.items = products.items.filter((x) => doLoadAllArticles || order?.orderItems?.some((y) => y.productId === x.data.id));
        products.items.forEach((item) => {
          item.data.productId = item.data.id;
        });
      }
      if (order?.orderItems) {
        order.orderItems.filter(y => products.items.some((o) => o.data.productId === y.productId))
          .forEach((y) => {
            const item = products.items.filter((o) => o.data.productId === y.productId)[0] ?? {};
            item.data.quantity = y.quantity!;
            item.data.id = y.id;
            item.data.selectedTitles = y.selectedTitles;
          });
      }
      return products;
    },
    async getProducts(isOnline: boolean, request: GetProductsRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<OrderItemArticleListResultModel>> {
      const resp = await baseService.getProducts(request, config);
      const ret = new OrderItemArticleListResultModel({ ...resp, items: resp.items.map((x) => x.data) } as DeepPartial<OrderItemArticleListResultModel>);
      return Promise.resolve(wrapResultWithDataRecord(ret));
    },
    async getProduct(isOnline: boolean, request: GetProductRequestModel, config?: RequestConfig) {
      const ret = await baseService.getProduct(request, config);
      return ret;
    },
    async importProducts(isOnline: boolean, request: ImportProductsRequestModel, config?: RequestConfig) {
      log.error('importProducts is not cached');
      return baseService.importProducts(request, config);
    },
    async importProductThumbnail(isOnline: boolean, request: ImportProductThumbnailRequestModel, config?: RequestConfig) {
      if (await caches.has('api-images')) {
        const id = request.id!;
        const cache = (await caches.open('api-images'));
        const endpointPath = (Vue.$config.values as any)['endpoints-import-product-thumbnail'] ?? `Products/${id}/Thumbnail`;
        const cacheItem = (await cache.keys()).filter((x) => x.url.indexOf(endpointPath) > 0);
        // eslint-disable-next-line no-restricted-syntax
        for (const element of cacheItem) {
          // eslint-disable-next-line no-await-in-loop
          await cache.delete(element?.url!);
        }
      }
      return baseService.importProductThumbnail(request, config);
    },
  };
};
