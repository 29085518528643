// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetProductCategoriesRequestModel from '../../model/get-product-categories-request-model';
import ProductCategoryListResultModel from '../../model/product-category-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getProductCategories(request: GetProductCategoriesRequestModel, config?: RequestConfig) {
    const requestDTO = GetProductCategoriesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-product-categories'] ?? 'ProductCategories';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ProductCategoryListResultModel.toModel(dto));
  },
});
