import Vue from 'vue';
import StorageTypes from './storage-types';

interface IdObject {
  id?: number;
}

export default class Cache<T extends IdObject> {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private type: StorageTypes) { }
  hasData(): boolean {
    return this.readAll().length > 0;
  }
  readAll(): T[] {
    return Vue.$localStorage.get<T[]>(this.type) || [];
  }
  read(id: number): T | undefined {
    const storageItems = this.readAll();
    return storageItems.filter((x) => id === x.id)[0]!;
  }

  update(...items: T[]): void {
    let storageItems = this.readAll();
    storageItems = storageItems.filter((x) => !items.some((y) => y.id === x.id));
    Vue.$localStorage.set(this.type, storageItems.concat(items));
  }

  remove(...items: T[]): void {
    this.removeById(...items.map((i) => i.id!));
  }
  removeById(...ids: number[]): void {
    let storageItems = this.readAll();
    storageItems = storageItems.filter((x) => ids.indexOf(x.id!) === -1);
    Vue.$localStorage.set(this.type, storageItems);
  }
  removeAll(): void {
    Vue.$localStorage.remove(this.type);
  }
}
