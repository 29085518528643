// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AccessTokenModel from '../../model/access-token-model';
import AccessTokenDTO from '../dto/access-token-dto';

export default abstract class AccessTokenModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AccessTokenDTO>) {
    super();
    if (data) {
      this.token = data.token ?? undefined;
      this.expiresOn = data.expiresOn;
    }
  }
  /**
  */
  'token'?: string;
  /**
  * @type {date-time}
  */
  'expiresOn'?: string;

  static toModel(dto: DeepPartial<AccessTokenDTO>): AccessTokenModel;
  static toModel(dto: DeepPartial<AccessTokenDTO> | undefined | null): AccessTokenModel | undefined;
  static toModel(dto: DeepPartial<AccessTokenDTO> | undefined | null): AccessTokenModel | undefined {
    return dto ? new AccessTokenModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AccessTokenModel> | AccessTokenModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'token': unwrapped.token,
      'expiresOn': unwrapped.expiresOn,
    } as AccessTokenDTO;
  }
}
