// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AppInfoModel from '../../model/app-info-model';
import AppInfoDTO from '../dto/app-info-dto';
import AppInfoComponentModel from '../../model/app-info-component-model';

export default abstract class AppInfoModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AppInfoDTO>) {
    super();
    if (data) {
      this.title = data.title ?? undefined;
      this.version = data.version ?? undefined;
      this.healthEndpoint = data.healthEndpoint ?? undefined;
      this.environment = data.environment ?? undefined;
      this.components = data.components?.map((i) => AppInfoComponentModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  'title'?: string;
  /**
  */
  'version'?: string;
  /**
  */
  'healthEndpoint'?: string;
  /**
  */
  'environment'?: string;
  /**
  */
  'components'?: AppInfoComponentModel[];

  static toModel(dto: DeepPartial<AppInfoDTO>): AppInfoModel;
  static toModel(dto: DeepPartial<AppInfoDTO> | undefined | null): AppInfoModel | undefined;
  static toModel(dto: DeepPartial<AppInfoDTO> | undefined | null): AppInfoModel | undefined {
    return dto ? new AppInfoModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AppInfoModel> | AppInfoModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'title': unwrapped.title,
      'version': unwrapped.version,
      'healthEndpoint': unwrapped.healthEndpoint,
      'environment': unwrapped.environment,
      'components': unwrapped.components,
    } as AppInfoDTO;
  }
}
