// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import IntegrationCallStateOverviewModel from '../../model/integration-call-state-overview-model';
import IntegrationCallStateOverviewDTO from '../dto/integration-call-state-overview-dto';

export default abstract class IntegrationCallStateOverviewModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<IntegrationCallStateOverviewDTO>) {
    super();
    if (data) {
      this.integrationDay = data.integrationDay;
      this.stateId = data.stateId;
      this.stateName = data.stateName ?? undefined;
      this.count = data.count;
    }
  }
  /**
  * @type {date-time}
  */
  'integrationDay'?: string;
  /**
  * @type {int64}
  */
  'stateId'?: number;
  /**
  */
  'stateName'?: string;
  /**
  * @type {int32}
  */
  'count'?: number;

  static toModel(dto: DeepPartial<IntegrationCallStateOverviewDTO>): IntegrationCallStateOverviewModel;
  static toModel(dto: DeepPartial<IntegrationCallStateOverviewDTO> | undefined | null): IntegrationCallStateOverviewModel | undefined;
  static toModel(dto: DeepPartial<IntegrationCallStateOverviewDTO> | undefined | null): IntegrationCallStateOverviewModel | undefined {
    return dto ? new IntegrationCallStateOverviewModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<IntegrationCallStateOverviewModel> | IntegrationCallStateOverviewModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'integrationDay': unwrapped.integrationDay,
      'stateId': unwrapped.stateId,
      'stateName': unwrapped.stateName,
      'count': unwrapped.count,
    } as IntegrationCallStateOverviewDTO;
  }
}
