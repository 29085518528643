// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetCustomerOrderListRequestModel from '../../model/get-customer-order-list-request-model';
import GetCustomerOrderListRequestDTO from '../dto/get-customer-order-list-request-dto';

export default abstract class GetCustomerOrderListRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetCustomerOrderListRequestDTO>) {
    super();
    if (data) {
      this.orderId = data.orderId;
      this.employeeId = data.employeeId;
      this.customerId = data.customerId;
      this.orderDateFrom = data.orderDateFrom;
      this.orderDateTo = data.orderDateTo;
      this.orderSentAtFrom = data.orderSentAtFrom;
      this.orderSentAtTo = data.orderSentAtTo;
      this.orderState = data.orderState;
      this['paging.CurrentPage'] = data['paging.CurrentPage'];
      this['paging.PageSize'] = data['paging.PageSize'];
      this['paging.IncludeCount'] = data['paging.IncludeCount'];
      this['paging.NoPaging'] = data['paging.NoPaging'];
    }
  }
  /**
  * @type {int64}
  */
  'orderId'?: number;
  /**
  * @type {int64}
  */
  'employeeId'?: number;
  /**
  * @type {int64}
  */
  'customerId'?: number;
  /**
  * @type {date-time}
  */
  'orderDateFrom'?: string;
  /**
  * @type {date-time}
  */
  'orderDateTo'?: string;
  /**
  * @type {date-time}
  */
  'orderSentAtFrom'?: string;
  /**
  * @type {date-time}
  */
  'orderSentAtTo'?: string;
  /**
  * @type {int32}
  */
  'orderState'?: 0 | 1 | 2 | 3 | 4; // OrderStateDTO
  /**
  * @type {int32}
  */
  'paging.CurrentPage'?: number;
  /**
  * @type {int32}
  */
  'paging.PageSize'?: number;
  /**
  */
  'paging.IncludeCount'?: boolean;
  /**
  */
  'paging.NoPaging'?: boolean;

  static toModel(dto: DeepPartial<GetCustomerOrderListRequestDTO>): GetCustomerOrderListRequestModel;
  static toModel(dto: DeepPartial<GetCustomerOrderListRequestDTO> | undefined | null): GetCustomerOrderListRequestModel | undefined;
  static toModel(dto: DeepPartial<GetCustomerOrderListRequestDTO> | undefined | null): GetCustomerOrderListRequestModel | undefined {
    return dto ? new GetCustomerOrderListRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetCustomerOrderListRequestModel> | GetCustomerOrderListRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'orderId': unwrapped.orderId,
      'employeeId': unwrapped.employeeId,
      'customerId': unwrapped.customerId,
      'orderDateFrom': unwrapped.orderDateFrom,
      'orderDateTo': unwrapped.orderDateTo,
      'orderSentAtFrom': unwrapped.orderSentAtFrom,
      'orderSentAtTo': unwrapped.orderSentAtTo,
      'orderState': unwrapped.orderState,
      'paging.CurrentPage': unwrapped['paging.CurrentPage'],
      'paging.PageSize': unwrapped['paging.PageSize'],
      'paging.IncludeCount': unwrapped['paging.IncludeCount'],
      'paging.NoPaging': unwrapped['paging.NoPaging'],
    } as GetCustomerOrderListRequestDTO;
  }
}
