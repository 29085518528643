import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CustomerOrderItemDTO from '../../../../services/v2/_generated/dto/customer-order-item-dto';
import OrderItemArticleModel from '../../../../services/v2/model/order-item-article-model';
import CustomerOrderItemModel from '../../../../services/v2/model/customer-order-item-model';
import CustomerModel from '../../../../services/v2/model/customer-model';
import CustomerOrderModel from '../../../../services/v2/model/customer-order-model';
import GetCustomerRequestModel from '../../../../services/v2/model/get-customer-request-model';
import GetCustomerOrdersByIdRequestModel from '../../../../services/v2/model/get-customer-orders-by-id-request-model';
import ProductCategoryModel from '../../../../services/v2/model/product-category-model';
import CategoryOrderItemArticleListResultModel from '../../../../services/v2/model/category-order-item-article-model';
import GetEmployeeByUserIdRequestModel from '../../../../services/v2/model/get-employee-by-user-id-request-model';

export default class CustomerOrderEditViewModel extends PageBase {
  storedOrder: CustomerOrderModel = new CustomerOrderModel();
  selectedCustomer: CustomerModel | undefined = undefined;
  selectedCategory: ProductCategoryModel | undefined = undefined;
  productCategories: ProductCategoryModel[] | undefined;
  noDataFound: boolean = false;
  doShowAllArticles: boolean = true;
  isReadOnly: boolean = true;
  employeeId: number | undefined;
  selectedTabId = 0;
  images: Record<number, string> = {};
  _employeeId: number | undefined;
  productColors: string[] = ['rgba(255,255,255,0)', 'rgba(255,255,0,0.3)', 'rgba(0,255,0,0.3)'];
  products: CategoryOrderItemArticleListResultModel[] = [];
  mainProductCategories: ProductCategoryModel[] = [];
  productsIsLoading: boolean | undefined;
  isLoading = true;

  async setCategory(category: ProductCategoryModel | undefined, forceLoad = false) {
    const canSetCategory = !this.productsIsLoading && this.selectedCategory !== category;
    if (canSetCategory || forceLoad) {
      this.doShowAllArticles = category?.isRoot ?? false;
      this.products = [];
      this.selectedCategory = category;
      try {
        this.productsIsLoading = true;
        this.products = await Vue.$service.v2.api.products.getProductsForOrderGroupedByCategories(Vue.$pwa.isOnline, {}, undefined, this.storedOrder, this.selectedCategory?.id, this.doShowAllArticles);
        if (category === undefined) {
          this.products.forEach((c) => { c.items = c.items.filter((x) => x.quantity > 0); });
          this.products = this.products.filter((c) => c.items.length > 0);
        }
      } catch (error: any) {
        Vue.$alert.error(error);
      } finally {
        this.productsIsLoading = false;
      }
    }
  }

  // .filter((customer) => customer.employeeId === employeeId)
  async filterCustomersByEmployeeId(state: boolean) {
    if (state) {
      if (!this._employeeId) {
        try {
          const me = await Vue.$service.v2.api.users.getUser(Vue.$pwa.isOnline);
          const request = new GetEmployeeByUserIdRequestModel({
            userId: me?.id!,
          });
          const employee = await Vue.$service.v2.api.employees.getEmployeeByUserId(Vue.$pwa.isOnline, request);
          Vue.set(this, '_employeeId', employee.data.id);
        } catch (error: any) {
          Vue.$alert.error(error);
        }
      }
      Vue.set(this, 'employeeId', this._employeeId);
    } else {
      Vue.set(this, 'employeeId', undefined);
    }
  }
  async saveAndClose() {
    Vue.$service.v2.api.customerOrders.updateLocalOrder(this.storedOrder);
    await Vue.$router.push('/bv/ordering-app').catch(() => { });
  }
  async selectCustomer(id: number, isinitializing = false): Promise<void> {
    if (id) {
      try {
        const item = (await Vue.$service.v2.api.customers.getCustomer(Vue.$pwa.isOnline, GetCustomerRequestModel.toModel({ id: id! }), undefined));
        if (item) {
          this.noDataFound = false;
        } else {
          this.noDataFound = true;
        }
        if (!isinitializing && id !== this.selectedCustomer?.id) {
          const calcDeliveryDate = this.calculateDeliveryDate(item.data)?.toISOString();
          Vue.set(this.storedOrder, 'deliveryDate', calcDeliveryDate);
        }
        Vue.set(this, 'selectedCustomer', undefined);
        Vue.set(this, 'selectedCustomer', item.data);
      } catch (error: any) {
        Vue.$alert.error(error);
      }
    } else {
      Vue.set(this.storedOrder, 'customerId', null);
      Vue.set(this.storedOrder, 'deliveryDate', null);
      this.selectedCustomer = undefined;
    }
  }

  async initialize(): Promise<void> {
    try {
      this.filterCustomersByEmployeeId(true);
      const storedOrderFromBackEnd = await Vue.$service.v2.api.customerOrders.getCustomerOrdersById(Vue.$pwa.isOnline, GetCustomerOrdersByIdRequestModel.toModel({ id: +Vue.$router.currentRoute.params.order }), undefined);
      Vue.set(this, 'storedOrder', storedOrderFromBackEnd.data);
      this.isReadOnly = storedOrderFromBackEnd.data.orderState !== 1;
      this.storedOrder = storedOrderFromBackEnd.data;
      await this.selectCustomer(storedOrderFromBackEnd.data.customerId!, true);
      this.productCategories = (await Vue.$service.v2.api.productCategories.getProductCategories(Vue.$pwa.isOnline, {}, undefined)).items.map((d) => d.data);
      if (this.isReadOnly) {
        this.doShowAllArticles = false;
        this.mainProductCategories = [];
      } else {
        this.mainProductCategories = this.productCategories.filter((c) => c.isRoot);
      }
      if (!this.doShowAllArticles || (this.storedOrder?.orderItems?.length || 0) > 0) {
        this.selectedTabId = this.mainProductCategories.length;
        await this.setCategory(undefined, true);
      } else {
        this.selectedTabId = 0;
        await this.setCategory(this.mainProductCategories[0], true);
      }
    } catch (error: any) {
      Vue.$alert.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  checkOrderItems(product: OrderItemArticleModel): OrderItemArticleModel | undefined {
    if (!this.storedOrder.orderItems) {
      this.storedOrder.orderItems = [];
    }
    if (!this.storedOrder.orderItems.some((o) => o.productId === product.productId)) {
      const p = new CustomerOrderItemModel({
        ...product, id: undefined, productId: product.id, customerOrderId: this.storedOrder.id,
      } as DeepPartial<CustomerOrderItemDTO>);
      this.storedOrder.orderItems.push(p);
    }
    this.storedOrder.orderItems = this.storedOrder.orderItems ?? [];
    return this.storedOrder.orderItems.find((o) => o.productId === product.productId) as OrderItemArticleModel;
  }

  async setOrderItemQuantity(product: OrderItemArticleModel, quantity: number | undefined) {
    const item = this.checkOrderItems(product);
    if (item !== undefined) {
      item!.quantity = quantity ?? 0;
    }
    this.storedOrder.orderItems = this.storedOrder.orderItems!;
    if (!this.selectedCategory && (quantity ?? 0) === 0) {
      this.products = this.products || [];
    }
  }

  setSubtitles(product: OrderItemArticleModel, subtitles: string[]) {
    const item = this.checkOrderItems(product);
    item!.selectedTitles = subtitles;
    this.storedOrder.orderItems = this.storedOrder.orderItems!.filter((x: CustomerOrderItemModel) => (x.quantity ?? 0) > 0);
    if (!this.selectedCategory && subtitles === undefined) {
      this.products.forEach((c) => { c.items = c.items.filter((x) => x.selectedTitles !== undefined); });
    }
  }

  calculateDeliveryDate(customer: CustomerModel): Date | undefined {
    const deliveryDayString = customer.deliveryDays as string;
    if (!deliveryDayString) {
      return undefined;
    }
    const days = new Map();
    days.set('mo', 1);
    days.set('tu', 2);
    days.set('we', 3);
    days.set('th', 4);
    days.set('fr', 5);
    days.set('sa', 6);
    days.set('su', 7);
    const deliveryDay = days.get(deliveryDayString.toLowerCase());
    const now = new Date();
    now.setDate(now.getDate() + (now.getHours() < 11 ? 0 : 1));
    const currentDay = now;
    const nextDeliveryDate = currentDay;
    let daysToAdd = (deliveryDay + 7 - nextDeliveryDate.getDay());
    if (daysToAdd >= 7) {
      daysToAdd -= 7;
    }
    nextDeliveryDate.setDate(nextDeliveryDate.getDate() + daysToAdd);

    return nextDeliveryDate;
  }
}
