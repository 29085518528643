import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import productsServices from '../../_generated/api/products';
import LookupPictureModifiedAfterRequestModel from '../../model/lookup-picture-modified-after-request-model';
import GetProductThumbnailRequestModel from '../../model/get-product-thumbnail-request-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';

export default (service: Servicelayer, log: Logger) => {
  const baseService = productsServices(service);
  return {
    async getProductImageModifiedAfter(request: LookupPictureModifiedAfterRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<Int64LookupListResultModel>> {
      const ret = await baseService.lookupPictureModifiedAfter(request, config);
      return ret;
    },
    async getProductImageForceOnline(isOnline: boolean, productId: number, config: RequestConfig): Promise<Blob> {
      const request = GetProductThumbnailRequestModel.toModel({ id: productId, width: 0, height: 200 });
      const image = await baseService.getProductThumbnail(request, config);
      return image.data;
    },
  };
};
