import { DataRecordWrapResponse, wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import CustomerListResultModel from '../../model/customer-list-result-model';
import CustomerModel from '../../model/customer-model';
import GetAllCustomersRequestModel from '../../model/get-all-customers-request-model';
import GetCustomerRequestModel from '../../model/get-customer-request-model';
import ImportCustomersRequestModel from '../../model/import-customers-request-model';
import Int64LookupModel from '../../model/int64-lookup-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';
import LookupCustomersRequestModel from '../../model/lookup-customers-request-model';
import ResourceLinkModel from '../../model/resource-link-model';
import customersServices from '../../_generated/api/customers';

export default (service: Servicelayer, log: Logger) => {
  const baseService = customersServices(service);

  return {
    async getAllCustomers(isOnline: boolean, request: GetAllCustomersRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<CustomerListResultModel>> {
      const ret = await baseService.getAllCustomers(request, config);
      return ret;
    },
    async lookupCustomers(isOnline: boolean, request: LookupCustomersRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<Int64LookupPagingResultModel>> {
      if (!isOnline) {
        const all = await this.getAllCustomers(isOnline, {}, config);
        const ret = all.items.filter((x) => x.data.employeeId === (request.employeeId || x.data.employeeId)
          && x.data.id === (request.id || x.data.id)
          && (!request.searchCriteria || (
            x.data.addressLineAddition?.includes(request.searchCriteria)
            || x.data.city?.includes(request.searchCriteria)
            || x.data.companyName?.includes(request.searchCriteria)
            || x.data.companyShortName?.includes(request.searchCriteria)
            || x.data.customerNumber?.includes(request.searchCriteria)
          )));
        return Promise.resolve(wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel({
          items: ret.map((data) => Int64LookupModel.toDTO({
            id: data.data.id,
            links: data.data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [],
            caption: data.data.companyShortName ?? undefined,
            details: data.data.companyName ?? undefined,
          })),
        })));
      }
      const ret = await baseService.lookupCustomers(request, config);
      return ret;
    },
    async getCustomer(isOnline: boolean, request: GetCustomerRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<CustomerModel>> {
      if (!isOnline) {
        const all = await this.getAllCustomers(isOnline, {}, config);
        return Promise.resolve(all.items.find((x) => x.data.id === request.id)!);
      }
      const ret = await baseService.getCustomer(request, config);
      return ret;
    },
    async importCustomers(isOnline: boolean, request: ImportCustomersRequestModel, config?: RequestConfig): Promise<void> {
      return baseService.importCustomers(request, config);
    },
  };
};
