// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ExecuteTransformCrmContactProcessRequestModel from '../../model/execute-transform-crm-contact-process-request-model';
import ExecuteTransformCrmContactProcessRequestDTO from '../dto/execute-transform-crm-contact-process-request-dto';

export default abstract class ExecuteTransformCrmContactProcessRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ExecuteTransformCrmContactProcessRequestDTO>) {
    super();
    if (data) {
      this.processName = data.processName;
    }
  }
  /**
  */
  'processName'?: string;

  static toModel(dto: DeepPartial<ExecuteTransformCrmContactProcessRequestDTO>): ExecuteTransformCrmContactProcessRequestModel;
  static toModel(dto: DeepPartial<ExecuteTransformCrmContactProcessRequestDTO> | undefined | null): ExecuteTransformCrmContactProcessRequestModel | undefined;
  static toModel(dto: DeepPartial<ExecuteTransformCrmContactProcessRequestDTO> | undefined | null): ExecuteTransformCrmContactProcessRequestModel | undefined {
    return dto ? new ExecuteTransformCrmContactProcessRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ExecuteTransformCrmContactProcessRequestModel> | ExecuteTransformCrmContactProcessRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'processName': unwrapped.processName,
    } as ExecuteTransformCrmContactProcessRequestDTO;
  }
}
