import Vue from 'vue';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import DataRecord, { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import CustomerOrderListPagingResultModel from '../../../../services/v2/model/customer-order-list-paging-result-model';
import GetCustomerOrderListRequestModel from '../../../../services/v2/model/get-customer-order-list-request-model';
import CustomerOrderListModel from '../../../../services/v2/model/customer-order-list-model';
import GetEmployeeByUserIdRequestModel from '../../../../services/v2/model/get-employee-by-user-id-request-model';

export default class CustomerOrderListViewModel extends PageBase {
  async initialize(): Promise<void> {
    const user = await Vue.$service.v2.api.users.getUser(Vue.$pwa.isOnline);
    const employee = await Vue.$service.v2.api.employees.getEmployeeByUserId(Vue.$pwa.isOnline, GetEmployeeByUserIdRequestModel.toModel({ userId: user?.id! }));
    this.customerOrdersDataSource.filter.employeeId = employee.data.id;
    await this.customerOrdersDataSource.select();
    await this.customerLocalOrdersDataSource.select();

    const handleNetworkChange = () => {
      this.customerOrdersDataSource.select();
      this.customerLocalOrdersDataSource.select();
    };
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);
  }

  customerLocalOrdersDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.customerOrders.getCustomerLocalOrderList,
  });
  customerOrdersDataSource = new DataSource({
    selectCommand: (request: GetCustomerOrderListRequestModel, config?: RequestConfig): Promise<DataRecordWrapResponse<CustomerOrderListPagingResultModel>> => Vue.$service.v2.api.customerOrders.getCustomerOrderList(Vue.$pwa.isOnline, request, config),
  });

  async refreshDataSources() {
    await this.customerLocalOrdersDataSource.select();
    await this.customerOrdersDataSource.select();
  }

  onEditClick(entry: DataRecord<CustomerOrderListModel>) {
    Vue.$router.push(`/bv/ordering-app/customer-order/edit/${entry.data.id}`);
  }
  async sendOrder(entry: DataRecord<CustomerOrderListModel>) {
    Vue.$dialog.confirm(Vue.$t('page.customerOrderList.confirm.sendOrder.title'), Vue.$t('page.customerOrderList.confirm.sendOrder.message'))
      .then((isConfirmed) => {
        if (isConfirmed) {
          (Vue.$service.v2.api.customerOrders.postCustomerOrder(Vue.$pwa.isOnline, entry.data.id!)).then(async () => {
            await this.refreshDataSources();
          });
        }
      });
  }
  onDeleteClick(entry: DataRecord<CustomerOrderListModel>) {
    Vue.$dialog.confirmDelete(1)

      .then((isConfirmed) => {
        if (isConfirmed) {
          Vue.$service.v2.api.customerOrders.deleteLocalCustomerOrderById(entry.data.id!);
          this.customerLocalOrdersDataSource.select();
        }
      });
  }
  getIsSendOrderDisabled(isOnline: boolean, entry: DataRecord<CustomerOrderListModel>): boolean {
    return !isOnline || !(entry.data.employeeId && entry.data.customerId && entry.data.orderDate && entry.data.deliveryDate && entry.data.orderItemsCount);
  }
  getIsSendOrderDisabledInfo(isOnline: boolean, entry: DataRecord<CustomerOrderListModel>): string[] {
    const ret: string[] = [];
    if (!isOnline) {
      ret.push(Vue.$t('page.customerOrderList.label.errorSendingorder.offline'));
    }
    if (!entry.data.employeeId) {
      ret.push(Vue.$t('page.customerOrderList.label.errorSendingorder.employeeId'));
    }
    if (!entry.data.customerId) {
      ret.push(Vue.$t('page.customerOrderList.label.errorSendingorder.customerId'));
    }
    if (!entry.data.orderDate) {
      ret.push(Vue.$t('page.customerOrderList.label.errorSendingorder.orderDate'));
    }
    if (!entry.data.deliveryDate) {
      ret.push(Vue.$t('page.customerOrderList.label.errorSendingorder.deliveryDate'));
    }
    if (entry.data.orderDate && entry.data.deliveryDate && entry.data.orderDate > entry.data.deliveryDate) {
      ret.push(Vue.$t('page.customerOrderList.label.errorSendingorder.orderDateAfterDeliveryDate'));
    }
    if (!entry.data.orderItemsCount) {
      ret.push(Vue.$t('page.customerOrderList.label.errorSendingorder.orderItemsCount'));
    }
    return ret;
  }
}
