// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetAllCustomersRequestModel from '../../model/get-all-customers-request-model';
import GetAllCustomersRequestDTO from '../dto/get-all-customers-request-dto';

export default abstract class GetAllCustomersRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetAllCustomersRequestDTO>) {
    super();
    if (data) {
      this.employeeId = data.employeeId;
      this.searchCriteria = data.searchCriteria;
      this.customerNumber = data.customerNumber;
    }
  }
  /**
  * @type {int64}
  */
  'employeeId'?: number;
  /**
  */
  'searchCriteria'?: string;
  /**
  */
  'customerNumber'?: string;

  static toModel(dto: DeepPartial<GetAllCustomersRequestDTO>): GetAllCustomersRequestModel;
  static toModel(dto: DeepPartial<GetAllCustomersRequestDTO> | undefined | null): GetAllCustomersRequestModel | undefined;
  static toModel(dto: DeepPartial<GetAllCustomersRequestDTO> | undefined | null): GetAllCustomersRequestModel | undefined {
    return dto ? new GetAllCustomersRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetAllCustomersRequestModel> | GetAllCustomersRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'employeeId': unwrapped.employeeId,
      'searchCriteria': unwrapped.searchCriteria,
      'customerNumber': unwrapped.customerNumber,
    } as GetAllCustomersRequestDTO;
  }
}
