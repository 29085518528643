// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PostDynamicFormRequestModel from '../../model/post-dynamic-form-request-model';
import PostDynamicFormRequestDTO from '../dto/post-dynamic-form-request-dto';
import DynamicFormPayloadModel from '../../model/dynamic-form-payload-model';

export default abstract class PostDynamicFormRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PostDynamicFormRequestDTO>) {
    super();
    if (data) {
      this.appSystemKey = data.appSystemKey;
      this.form = DynamicFormPayloadModel.toModel(data.form);
    }
  }
  /**
  */
  'appSystemKey'?: string;
  /**
  */
  'form'?: DynamicFormPayloadModel;

  static toModel(dto: DeepPartial<PostDynamicFormRequestDTO>): PostDynamicFormRequestModel;
  static toModel(dto: DeepPartial<PostDynamicFormRequestDTO> | undefined | null): PostDynamicFormRequestModel | undefined;
  static toModel(dto: DeepPartial<PostDynamicFormRequestDTO> | undefined | null): PostDynamicFormRequestModel | undefined {
    return dto ? new PostDynamicFormRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PostDynamicFormRequestModel> | PostDynamicFormRequestModel) {
    const unwrapped = unwrapDataRecord(model);
    return {
      'appSystemKey': unwrapped.appSystemKey,
      'form': unwrapped.form,
    } as PostDynamicFormRequestDTO;
  }
}
