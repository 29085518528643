// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCustomerOrderListRequestModel from '../../model/get-customer-order-list-request-model';
import PostCustomerOrderRequestModel from '../../model/post-customer-order-request-model';
import GetCustomerOrdersByIdRequestModel from '../../model/get-customer-orders-by-id-request-model';
import DownloadAbaFileRequestModel from '../../model/download-aba-file-request-model';
import CustomerOrderListPagingResultModel from '../../model/customer-order-list-paging-result-model';
import CustomerOrderModel from '../../model/customer-order-model';

export default (service: Servicelayer) => ({
  /**  */
  async getCustomerOrderList(request: GetCustomerOrderListRequestModel, config?: RequestConfig) {
    const requestDTO = GetCustomerOrderListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-customer-order-list'] ?? 'CustomerOrders';
    const response = await service.get<any>(endpointPath, {
      query: {
        orderId: requestDTO.orderId,
        employeeId: requestDTO.employeeId,
        customerId: requestDTO.customerId,
        orderDateFrom: requestDTO.orderDateFrom,
        orderDateTo: requestDTO.orderDateTo,
        orderSentAtFrom: requestDTO.orderSentAtFrom,
        orderSentAtTo: requestDTO.orderSentAtTo,
        orderState: requestDTO.orderState,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CustomerOrderListPagingResultModel.toModel(dto));
  },
  /**  */
  async postCustomerOrder(request: PostCustomerOrderRequestModel, config?: RequestConfig) {
    const requestDTO = PostCustomerOrderRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-post-customer-order'] ?? 'CustomerOrders';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        employeeId: requestDTO.employeeId,
        customerId: requestDTO.customerId,
        orderDate: requestDTO.orderDate,
        deliveryDate: requestDTO.deliveryDate,
        orderItems: requestDTO.orderItems,
        comment: requestDTO.comment,
        orderType: requestDTO.orderType,
        orderState: requestDTO.orderState,
        orderSentState: requestDTO.orderSentState,
        orderSentMessage: requestDTO.orderSentMessage,
        orderSentAt: requestDTO.orderSentAt,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CustomerOrderModel.toModel(dto));
  },
  /**  */
  async getCustomerOrdersById(request: GetCustomerOrdersByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetCustomerOrdersByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-customer-orders-by-id'] ?? 'CustomerOrders/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CustomerOrderModel.toModel(dto));
  },
  /**  */
  async downloadAbaFile(request: DownloadAbaFileRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadAbaFileRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-aba-file'] ?? 'CustomerOrders/DownloadFile';
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      query: {
        orderId: requestDTO.orderId,
        employeeId: requestDTO.employeeId,
        customerId: requestDTO.customerId,
        orderDateFrom: requestDTO.orderDateFrom,
        orderDateTo: requestDTO.orderDateTo,
        orderSentAtFrom: requestDTO.orderSentAtFrom,
        orderSentAtTo: requestDTO.orderSentAtTo,
        orderState: requestDTO.orderState,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
